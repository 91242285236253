<template>
  <div id='body' class='transition' :style="getAppStyle()+'width: 100%; height: calc(100vH);'">
   
    <div v-if='entityList && entityList.length'>
	    <div v-for='(entity, idx) in entityList' :key="'e_'+idx" 
	        :style="'float: left; width: calc('+99/entityList.length+'%); padding-left: 5pt; padding-right: 2pt; border-right: 0.5pt solid #eee;'"> 
	        
	  		<GFWE v-if="!entity.startsWith('/')" :entity='entity' @close="removeModule" @clearAll="clearAll()"/>
	  		<div v-else-if='! entity.startsWith("/fw/")'>
	  			{{$router.replace(entity)}}
	  		</div>
	  	</div>
	  	
  	</div>
  	<div v-else style='padding-left: 1pt;height: calc(100vH - 110px);' @contextmenu.prevent="ctxMenu($event, {})">
  	   
  	    <div class='appHeadLine' style='width: 100% !important;' @click='getUser(true)'>
  	     <div style='position: relative; float: right; width: 100% !important; padding-bottom: 2pt;'>
  	     <div style='position: relative; float: left;'>
  	      <div class='breadcrumbItemCurrent transition' :style="getAppStyle()">
  		   Data Miner for {{user.login}} 
  		  </div>
  		 
  		  </div>
  		  <div  style='float: right'>   
  		        <WindowCloseBN @click="$router.replace('/fw/myDashBoard')" tooltip='Switch to module-desktop'/>	    
		  </div>
  		 </div>
  		</div>
		
  		<div style='float: top; display: block; padding-top: 5pt; width: 100%;'>
  		
  		<div id='tabHead' style='margin-right: 5pt; padding-bottom: -1px; display: block; float: left; width: 100%; 
  		     font-size: 10pt; height: 32px; padding-top: 3px;'>
			
			<div :style="'z-index: 0; position: absolute; left: '+(i*80)+'pt;'" 
			     v-for="(t,i) in buttons" :key="'bn_'+i" :class="(selectedTitle==i)?'buttonTab selected':'buttonTab'" 
			@click="tabChange(i)">
			{{tr(t)}}
			</div>
			
			<div :style="'z-index: 0; position: absolute; left: '+(buttons.length*80+20)+'pt;'" 
			  :class="(view=='checks')?'buttonTab selectedRed':'buttonTab'" 
			@click="openCheck();">
			Checks<span style='width: 3pt;'></span>
			
			<span v-if='initialCheckResults.blocker' class='w3-badge w3-red'>{{initialCheckResults.blocker}}</span>
			<span v-else-if='initialCheckResults.found' class='w3-badge w3-blue'>{{initialCheckResults.found}}</span>
			</div>
			
			<div class='transition' :style="getAppStyle()+'height: 12px; left: 0pt; top: '+getTabPos()+'px; width: 100%; z-index: 0; border-top: 1px solid grey; position: absolute;'">
			
			</div>
			
  		</div>
  		
  		<div class='transition' :style="getAppStyle()+'float: left; display: block; width: 79.5%; padding-top: 8pt;'">

			<div style='float: left; width: 25%;'>
			        <InputSearch v-model="searchTerm" :topic="tr(buttons[selectedTitle])" @search="doSearch(true)" usage='dashboard'/>
	  				
    		
    	 		 <div v-if="data && data.meta" 
    	 		     style='float: left; display: block;  position: relative; z-index: 0; margin-top:10pt; margin-right:0pt; width: 100%; height: calc(100vH - 200px); overflow-x: hidden; overflow-y: scroll;' class="scrollable-element">					
				
			    	   <span v-for="(d,di) in filterList(data.data, checkFilter)" :key="'i_'+di"  
			    	        style="z-index: 0; width: 100%;">
			    	       
			    	        <dt class="dt" :style="'background: linear-gradient(45deg, #88d, '+getBG()+');'" v-if="d.header" 
			    	           >
			    	            {{d.header}}
			    	        </dt>
			    	       
			    	   		<div v-else :class="d.id==selectedData.id?'ddItem selected':'ddItem'"  
			    	   		     :style="'fontSize:'+Math.min(fontSize-4,11)+'pt !important;'"
			    	   		@mouseenter="setModule(d);"
						    @mouseleave="setModule({});" :title='d.label'
			    	   		@click="clickOn(d)">{{d.label}}</div>
			    	   </span>
    	   		 </div> 
    	   </div>
    	   
    	  <div v-if="view==='money' && selectedData && selectedData.id" 
    	       :style="'display: block; box-shadow: '+attr.shadow1+'px '+attr.shadow1+'px '+attr.shadow2+'px 0 '+attr.shadowColor+';'+
    	               'position: relative; z-index: 0; padding-top: 2pt; padding-left: 2pt;  '+
    	               'margin-top: 5pt; width: 75%; height: 125px; '+
    	               'background-image: linear-gradient('+colorTable['infoBoardStart']+', '+colorTable['infoBoard']+');'+ 
    	               'border-radius: '+attr.rounding+'px; float: left;'"
    	       @mouseenter="setColorTableSelection('infoBoardStart', 'infoBoard');"
    	        
    	       >
    	  
    	  <div :style="'color: white; background-image: linear-gradient( grey, '+colorTable['infoBoardStart']+');text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.3); border-radius: '+attr.rounding+'px;padding-left: 6pt; padding: 4pt;'">{{tr( selectedData.entity)}}: <b>{{selectedData.label}}</b></div> 
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.clients" :attr="attr" module='Clients' :modules="buttons" :name="tr('Client')" :data="dataInfo.clients"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.client" :attr="attr"  module='Clients' :modules="buttons" :name="tr('Client')" :data="dataInfo.client"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.agency" :attr="attr" module='Agencies' :modules="buttons" name="Agency" :data="dataInfo.agency"  @open="select"/>
    	  
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.salesRequestsOnAir" :attr="attr" module='SalesRequest' :modules="buttons" name="Sales Requests" :data="dataInfo.salesRequestsOnAir"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.salesRequests" :attr="attr" module='SalesRequest' :modules="buttons" name="Sales Requests" :data="dataInfo.salesRequests"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.media" :attr="attr" module='Media' :modules="buttons"  :name="tr('Media')" :data="dataInfo.media"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.campaignsOnAir" :attr="attr" module='Campaign' :modules="buttons"  name="Campaigns" :data="dataInfo.campaignsOnAir"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.products" :attr="attr" :modules="buttons"  :name="tr('Product')+'s'" :data="dataInfo.products" module='Product' @open="select"/>
		  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.productLabel" :attr="attr" :modules="buttons"  :name="tr('ProductLabel')+'s'" :data="dataInfo.productLabel" module='ProductLabel' @open="select"/>
		  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.productLabels" :attr="attr" :modules="buttons"  :name="tr('ProductLabel')+'s'" :data="dataInfo.productLabels" module='ProductLabel' @open="select"/>
		  		      	  
		  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.product" :attr="attr" module='Product' :modules="buttons" :name="tr('Product')" :data="dataInfo.product"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.durations" :attr="attr" name="Durations" module='100' :data="dataInfo.durations"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.businessTypes" :attr="attr" :name="tr('BusinessType')" module='101' :data="dataInfo.businessTypes"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.copies" :attr="attr" module='Copy' name="Copies" :modules="buttons"  :data="dataInfo.copies"  @open="select"/>
    	  
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.family" :attr="attr" :name="tr('Product')+'-Families'" :modules="buttons" named='Families' module='ProductFamily' :data="dataInfo.family"  @open="select"/>
    	  
    	  <ShowInfoDataDashboard v-if="selectedTitle==0 && dataInfo && dataInfo.group" :attr="attr" module='Holding' :modules="buttons"  :name="'Holding'" :data="dataInfo.group"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="selectedTitle==1 && dataInfo && dataInfo.group" :attr="attr" module='Holding' :modules="buttons"  :name="'Network'" :data="dataInfo.group"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.families" :attr="attr" :name="tr('Product')+'-Families'" :modules="buttons" named='Families' module='ProductFamily' :data="dataInfo.families"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.competitors" :attr="attr" name="Competitors" module='Clients' :modules="buttons"  :data="dataInfo.competitors" @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.competition" :attr="attr" name="Competitors" module='Product' :modules="buttons"  :data="dataInfo.competition" @open="select"/>
		  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.competitionBrands" :attr="attr" name="Competitors" module='ProductLabel' :modules="buttons"  :data="dataInfo.competitionBrands" @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.placements" :attr="attr" module='Placement' name="Placements" :modules="buttons"  :data="dataInfo.placements"  @open="select"/>
    	  <ShowInfoDataDashboard v-if="dataInfo && dataInfo.clashes" :attr="attr" module='Clashes' name="Clashes" :data="dataInfo.clashes"  @open="select"/>
    	  
    	  
    	  
    	  </div> 
    	  
    	  <div v-if="data && data.meta" style='float: left; width: 75%; height: calc(100vH - 280px); overflow-y: scroll;'>

		       <div v-if="view==='checks'" style='display: block; '>
			       <div style='display: block; position: sticky; top: 0px;  width: 100%;'>
			       <table width="100%">
				       <tr><td width="64%">
				       <table style='table-layout:fixed;' v-if="checkResult" width='100%'>
				       <tr 
				 
		    	       @mouseenter="setColorTableSelection('issueTable');"
				       >
				        <td :style="getColours4Issues('issueTable')" title='name of the check' class='tHeader bigger' width='14%'>Check</td>
				        <td :style="getColours4Issues('issueTable')" title='type of the check (playlist or invoicing)' class='tHeader bigger' width='7%'>Relevancy</td>
				        <td :style="getColours4Issues('issueTable')" title='checked dates start date' class='tHeader bigger' width='8%'>From</td>
				        <td :style="getColours4Issues('issueTable')" title='checked dates end date' class='tHeader bigger' width='8%'>To</td>
				        <td :style="getColours4Issues('issueTable')" title='Spots or days that were checked' class='tHeader bigger' width='7%'>Checked</td>
				        <td :style="getColours4Issues('issueTable')" class='tHeader bigger' width='7%'>Blocker</td>
				        <td :style="getColours4Issues('issueTable')" class='tHeader bigger' width='7%'>Issues</td>
				        <td :style="getColours4Issues('issueTable')" class='tHeader bigger' width='7%'>Warnings</td>
				        <td :style="getColours4Issues('issueTable')" class='tHeader bigger' width='12%'>Problems found</td>
				        </tr>
				      
				       		<tr v-for="(c,i) in filterChecks( checkResult)" :key="'cx_'+i" :title='c.check.name'
				       		  
				       		    :style="(selectedCheckResult && selectedCheckResult.check && selectedCheckResult.check.id===c.check.id)?getColours4Issues('selectedIssue'):(highlightIssue==c.categ?'border-bottom: 1px solid #eee; color: #444; background-color: #ffd !important;':'border-bottom: 1px solid #eee;')"
				       		    :class="'tLine small ' +((c.result.blocker+c.result.issues+c.result.warnings)?'issue':'')" 
				       		    @mouseenter="selectCheck(c);setColorTableSelection('selectedIssue');"
					            @mouseleave="selectCheck({});"
				       		    @click="displayCheck(c.check, c.result, c.actions, c.from, c.to, c.columns)">
				       		<td :class="'small noBreak '+((c.result.blocker+c.result.issues+c.result.warnings)?'':'ok')">
				       	 	{{c.check.name}}
				       		
				       		</td>
				       		<td :class="'tiny '+((c.result.blocker+c.result.issues+c.result.warnings)?'':'ok')">{{c.check.checkForValue}}</td>
				       		<td :class="'tiny '+((c.result.blocker+c.result.issues+c.result.warnings)?'':'ok')">{{c.from}}</td>
				            <td :class="'tiny '+((c.result.blocker+c.result.issues+c.result.warnings)?'':'ok')">{{c.to}}</td>
				            <td :class="'small right ' +((c.result.blocker+c.result.issues+c.result.warnings)?'':'ok')">
				       		<span v-if="c.result.spots">{{c.result.spots}}</span>
				       		</td>
				       		<td :class="'small right blocker ' +((c.result.blocker+c.result.issues+c.result.warnings)?'':'ok')">
				       		<span v-if="c.result.blocker">{{c.result.blocker}}</span>
				       		</td>
				       		<td :class="'right issue ' +((c.result.blocker+c.result.issues+c.result.warnings)?'':'ok')">
				       		<span v-if="c.result.issues">{{c.result.issues}}</span>
				       		</td>
				       		<td :class="'small right ' +((c.result.blocker+c.result.issues+c.result.warnings)?'':'ok')">
				       		<span v-if="c.result.warnings">{{c.result.warnings}}</span>
				       		</td>
				       		<td :class="'small right'">
							     	<div v-if='c.result.blocker+c.result.issues+c.result.warnings' style='width:calc(100) !important; white-space: nowrap; overflow: hidden; margin-top: 1pt;'>
							     	<GProgressRED :blocker='c.result.blocker' :issues='c.result.issues' :height="12" :percentage="100*(c.result.blocker+c.result.issues+c.result.warnings)/c.result.spots"/>
							     	</div>
							     	<div v-else class='noFindings'></div>
							     	</td>
				       		</tr>
				       </table> 
				       </td>
				       <td :style="'padding-top:20pt; text-shadow: 0;'">
				         <div :style="getAppStyle()" id='checkChart' />
				       </td>
				       </tr>
			       </table>
		       </div>
		       <div v-if="!checkResult" style='font-size: 18pt; color: #ddd; padding-left: 80pt; padding-top: 50pt;'>
		       performing checks...
		       </div>
		       <br/>
		       <div v-if="selectedCheckResult && selectedCheckResult.check">
			       <div style='border-bottom: 1pt solid blue;'>Results for <b>{{selectedCheckResult.check.name}}</b></div>
			       <div style='padding: 10pt; height: 55pt; width: 100%; font-size: 8pt;' >
			           <div>
					       <span v-if="selectedCheckResult.check && selectedCheckResult.check.manualTreatmentText" style='margin-bottom: 5pt;'>
					       Hint: {{selectedCheckResult.check.manualTreatmentText}}
					       </span>
					       <button :style="getAppStyle()+'margin-right: 10pt;float: right;'" :title="'Run check '+selectedCheckResult.check.name+' again.'" @click="rerunCheck(selectedCheckResult.check)" :class="'buttonActionSmall'" type="button" ><mdicon :width="14" name="reload" /></button>
				       </div>
						
						
				       <div class='noLineBreak' style='margin-top: 4pt; display: block; width: calc(100vH) !important; font-size: 8pt;' >
				       <span v-for="(a,ai) in selectedCheckResult.actions" :key="'ac_'+ai">
				       	<button class='button' :style="getAppStyle()" :title="'open masterdata editor for '+a.text" v-if="a.action==='editor'" @click="$refs.editor.open( a.module, a.dataId); setRerunCheck(selectedCheckResult.check)" :class="'buttonActionSmall'" type="button" >{{a.text}}</button>
				       	<button class='button' :style="getAppStyle()" :title="'open daily-rundown for '+a.text" v-if="a.action==='openDetailPlan'" @click="openDetailPlanWD( a.dataId, a.date); setRerunCheck(selectedCheckResult.check)" :class="'buttonActionSmall'" type="button" >Rundown {{a.text}}</button>
				        <button class='button' :style="getAppStyle()" :title="'open channel-dashboard for '+a.text" v-if="a.action==='openChannelDashboard'" @click="openChannelDashboard( a.dataId); setRerunCheck(selectedCheckResult.check)" :class="'buttonActionSmall'" type="button" >Channel-Dashboard {{a.text}}</button>
				        <button class='button' :style="getAppStyle()" :title="'open avails report for '+a.text" v-if="a.action==='openAvails'" @click="openAvailsReport( a.dataId, a.date);" :class="'buttonActionSmall'" type="button" >AvailsRep. {{a.text}}</button>
				        
				        <button class='button' :style="getAppStyle()" :title="'open client-reports for '+a.text" v-if="a.action==='clientReport'" 
				            @click="openClientReport( a.dataId, selectedCheckResult.from, selectedCheckResult.to); setRerunCheck(selectedCheckResult.check)" :class="'buttonActionSmall'" type="button" >Report for {{a.text}}</button>
						<button class='button' :style="getAppStyle()" :title="'open Invoicing for '+a.text" v-if="a.action==='invoicing'" 
				            @click="openInvoicing( a.dataId, selectedCheckResult.from, selectedCheckResult.to); setRerunCheck(selectedCheckResult.check)" :class="'buttonActionSmall'" type="button" >Invoices for {{a.text}}</button>
				        <button class='button' :style="getAppStyle()" :title="'Traffic Manager for '+a.text" v-if="a.action==='bookMan'" 
				            @click="openBookManPlcm( a.dataId, new Date(selectedCheckResult.from), new Date(selectedCheckResult.to)); setRerunCheck(selectedCheckResult.check)" :class="'buttonActionSmall'" type="button" >Traffic Manager {{a.text}}</button>
				       </span>
			       		</div>
			       </div>
		       	   <div v-if="selectedCheckResult && selectedCheckResult.items" style='height: calc(30vH); overflow-y: scroll;'>
		      			
				       <table width='100%' :style='getAppStyle()' id='iTab'>
				        <tr >
					        <th class='tHeader' 
					          :style="'position: sticky; top: 0; color: '+getBGColor('#008','#bbf')+';background-color: '+getBGColor('#ccc','#333')"
			    	          @mouseenter="setColorTableSelection('issueDetailTable');"
					        v-for="(c,ci,cn) in selectedCheckResult.columns" :key="'colh_'+cn">
					        {{tr(c.header)}}
					        </th>
				         
				        </tr>
				        <tr class='tLine small' :style="'fontSize:'+Math.min(fontSize-1,12)+'px !important;'" v-for="(e,i,n) in selectedCheckResult.lines" :key="'is_'+n"
				                @mouseenter="selectCheckResult(e);"
					            @mouseleave="selectCheckResult({});"
				        >
					        <td v-for="(c,ci,cn) in selectedCheckResult.columns" :key="'col_'+n+'_'+cn" :id="'col_'+n+'_'+cn" 
					            style="text-align: left;white-space: nowrap; text-overflow: ellipsis;">
					          <div :style="'max-width: 120px !important; overflow: hidden;text-align: left;white-space: nowrap; text-overflow: ellipsis;'">{{printColumn( selectedCheckResult.lines[i][c.col], c)}}</div>
					        </td>
				        </tr>
				        </table>
	                </div>
	           </div>
	           <div v-else>
	            <div v-if='checkResult' style='border-bottom: 1pt solid blue; height: 1em;'>&nbsp; </div>
			       <div style='padding: 10pt; height: 55pt; width: 100%; font-size: 8pt;' >
			           <div>
					      
				       </div>
	
				       <div class='noLineBreak' style='margin-top: 4pt; display: block; width: calc(100vH) !important; font-size: 8pt;' >
				       
			       		</div>
			       </div>
		       	   <div style='height: calc(30vH); overflow-y: scroll;'>
		       	   </div>
	           </div>
		       </div>
		       <div v-else-if="view==='money'" style='display: block; margin-top:7pt; width: 100%;'>
		       		
		 			<div class="transition" :style="'float: left; display: block; height:'+((showGraph)?'calc(100vH - 320px) !important;':'0pt !important;')+'width: 74% !important; overflow-y: hidden; position: relative; font-size: 12px; border-right: 2px solid #ccf;'">
		 			<div v-if="chartOptions.chart.type != 'xbar'" style='display: flex; height: 6%; padding-top: 2pt;'>
						<span style='padding: 4pt; font-size: 10pt;'>Style</span> 
						<vSelect v-if="chartOptions.chart.type === 'pie' || chartOptions.chart.type === 'donut'"  style='width: 15%;' :options="['pie','donut']" v-model="chartType">
						</vSelect>
						<vSelect v-else  style='width: 20%;' :options="['bar','line','area']" v-model="chartType">
						</vSelect>
						<span v-if="chartOptions.chart.type === 'pie' || chartOptions.chart.type === 'donut'" style='padding: 4pt; font-size: 10pt;'>Base</span>  
						<vSelect v-if="chartOptions.chart.type === 'pie' || chartOptions.chart.type === 'donut'" style='width: 18%;' :options="['amount','spots','grp']" v-model="chartBase" @input='changeChartBase'>
						</vSelect>
						
						<span v-if="chartOptions.chart.type !== 'pie' && chartOptions.chart.type !== 'donut'" style='padding: 4pt; font-size: 10pt;'>Data</span>  
						<vSelect v-if="chartOptions.chart.type !== 'pie' && chartOptions.chart.type !== 'donut'" style='width: 18%;' :options="['individual', 'cumulative']" v-model="cumulative">
						</vSelect>
						
						<span style='padding: 4pt; font-size: 10pt;'>Color Scheme</span>  
						<vSelect style='width: 18%;' :options="['palette1','palette2','palette3','palette4','palette5','palette6','palette7','palette8','palette9','palette10']" v-model="chartTheme">
						</vSelect>
						<span style='padding: 4pt; font-size: 10pt;'>Markers</span>  
						<vSelect style='width: 12%;' :options="['on', 'off']" v-model="chartMarkers">
						</vSelect>
					</div>
					<div style='font-size: 18px; width: 100%; text-align: center;'>{{chartText}}</div>	  
					
					<div :style="((chartType=='donut' || chartType=='pie')? '':'')+'color: #000;'" id='aChart' />

 				</div>
		 			
 				<div class="transition" :style="'float: left; display: block; height:'+((showGraph)?'calc(100vH - 290px);':'0pt;')+'width: 24% !important; overflow-y: auto;overflow-x: hidden; position: relative; font-size: 12px;'">
		 			<div v-if="view==='money'&& selectedData && selectedData.id && selectedTitle < 100" style='display: block; float: top;width: 100%; padding-left: 10pt;'>
				        	<div style=' padding-top: 4pt; padding-bottom: 5pt; font-weight: bold;'>
				        	Actions for {{selectedData.label}}:
				        	</div>
				            
				        	<button :class="'buttonAction'" type="button" :title="'Open masterdata for '+selectedData.label" :style="getAppStyle()"
		    	  	        @click="$refs.editor.open( activeModule, selectedData.id)">Open {{selectedData.label}}</button>
		    	  	        
		    	  	        <button v-if="selectedTitle==1" :class="'buttonAction'" type="button" 
		    	  	        @click="getEntitySplit(selectedData, 'agencyId', 'Client', '')">Get {{tr('Client')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Product percentage'"  v-if="selectedTitle!=1 && activeModule=='Client'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'clientId', 'Product', 'productId')">Get {{tr('Product')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Product percentage'"  v-if="activeModule=='ProductFamily'" :class="'buttonAction'" type="button" :style="getAppStyle()" 
		    	  	        @click="getEntitySplit(selectedData,'familyId', 'Product', 'productId')">Get {{tr('Product')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Material/Copy percentage'"  v-if="selectedTitle!=1 && activeModule=='Client'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'clientId', 'Copy', 'copyId')">Get {{tr('Copy')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Material/Copy percentage'"  v-if="activeModule=='Product'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'productId', 'Copy', 'copyId')">Get {{tr('Copy')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Material/Copy percentage'"  v-if="activeModule=='Placement'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'placementId', 'Copy', 'copyId')">Get {{tr('Copy')}} Split</button>
		    	  	        
		    	  	         <button :title="'Visualize Material/Copy percentage'"  v-if="activeModule=='Campaign'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'campaignId', 'Copy', 'copyId')">Get {{tr('Copy')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Channel percentage'"  v-if="selectedTitle!=1 && activeModule=='Client'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'clientId', 'Media', 'mediaId')">Get {{tr('Media')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Channel percentage'"  v-if="activeModule=='Product'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'productId', 'Media', 'mediaId')">Get {{tr('Media')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Channel percentage'"  v-if="activeModule=='Copy'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'copyId', 'Media', 'mediaId')">Get {{tr('Media')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Grid/Program percentage'"  v-if="activeModule=='SalesRequest'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'salesRequestId', 'GridDetail', 'gridId')">Get {{tr('Grid')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Grid/Program percentage'"  v-if="activeModule=='Placement'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'placementId', 'GridDetail', 'gridId')">Get {{tr('Grid')}} Split</button>
		    	  	        
		    	  	        <button :title="'Visualize Grid/Program percentage'"  v-if="selectedTitle!=1 && activeModule=='Client'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="getEntitySplit(selectedData,'clientId', 'GridDetail', 'gridId')">Get {{tr('Grid')}} Split</button>
		    	  	        
		    	  	         <button v-if="activeModule=='Media'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	       @click="getRevenue( selectedData, selectedTitle, selectedModule, false, {channelTarget: true} )">Show booked vs. target</button>
		    	  	        
		    	  	       
		    	  	        <button v-if="activeModule=='Media'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	       @click="getEntitySplit(selectedData, 'channelId', 'Client', '')">Get {{tr('Client')}} Split</button>
		    	  	       
		    	  	       <button v-if="activeModule=='GridDetail'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	       @click="getEntitySplit(selectedData, 'gridId', 'Client', '')">Get {{tr('Client')}} Split</button>
		    	 
		    	  	       <button v-if="activeModule=='ClientGroup'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	       @click="getEntitySplit(selectedData,'holdingId', 'Client', '')">Get {{tr('Client')}} Split</button>
		    	  	       
		    	  	        <button v-if="activeModule=='Placement'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="openBookMan( selectedData )">Traffic Manager</button>
		    	  	        
		    	  	        <button v-if="activeModule=='Client'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="open({ 'title':'Invoice','entity': getLink( invoiceView, selectedData) })">View Invoices</button>
		    	  	        
		    	  	        <button v-if="activeModule=='SalesRequest'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="openBookMan( selectedData )">Traffic Manager</button>
		    	  	           	  	       
		    	  	        <button v-if="activeModule=='Client'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	         :title="'Open booking status report'"
		    	  	         @click="open({ 'title':'Client Report','entity': getLink('/reporter', selectedData) })">View Client report</button>
		    	  	         
		    	  	        <button v-if="activeModule=='SalesRequest'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	         @click="open({ 'title':'Client Report','entity': getLink('/reporter', selectedData) })">View Client report</button>
		    	  	         
		    	  	        <button v-if="activeModule=='Media'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="openChannelDashboard( selectedData.id)">Channel Dashboard</button>
		    	  	        
		    	  	        <button v-if="activeModule=='Media'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="openScheduleView( selectedData.id)">View current grid</button>
		    	  	         
		    	  	        <button v-if="activeModule=='Media'" :class="'buttonAction'" type="button" :style="getAppStyle()"
		    	  	        @click="openAvailsReport( selectedData.id)">View Avails Report</button>
		    	  	        <div style='height: 5pt;'></div>
		    	  	        <button :class="'buttonAction' + (compareWith?' selected':'')" type="button" :style="getAppStyle()"
		    	  	         :title="(!compareWith?('Compare '+ (compareWith?compareWith.label:selectedData.label)+' with other selection'):('Stop comparation mode'))"
		    	  	         @click="setCompare( selectedData)">Compare {{(compareWith?compareWith.label:selectedData.label)}} with...</button>
		    	  	         
		    	  	        
		    	  	         
		    	  	        <div>
		    	  	        <button :title="'Reload data'" :class="'buttonAction2'" type="button" 
		    	  	                :style="getAppStyle()+'float: left; margin-top: 14pt; width: 40% !important'"
		    	  	        @click="select( selectedData.entity, selectedData)"><mdicon :width="16" name="reload" /></button>
		    	  	        
		    	  	        <button :disabled="history.length<2" :title="history.length>1?('Go back to '+goBackTo().label):''" 
		    	  	                :class="'buttonAction2'" type="button"  
		    	  	                :style="getAppStyle()+'float: right; margin-top: 14pt; width: 40% !important'"
		    	  	        @click='goBack'><mdicon :disabled="history.length<2" :width="16" name="history" /></button>
		    	  	        </div>
				     </div>
				     <div v-if="dataInfo.info" style='position: absolute; bottom: 0; padding-bottom: 25pt; 
				                                      padding-left: 4pt; width: calc(100) !important; 
				                                      overflow-x: auto; display: block; border-top: 2px solid #ccf;'>
				                                      
				        <div v-if="compareWith" style="'margin-top: 5px; color: #aaa; width: 100%; table-layout:fixed; fontSize:'+Math.min(fontSize-4,11)+'pt !important;'">{{selectedData.label}}</div>                              
				        
				        <table :style="'margin-top: 5px; width: 100%; table-layout:fixed; fontSize:'+Math.min(fontSize-4,11)+'pt !important;'" >
				            <tr v-if="dataInfo.info['Req. Amount']">
				            <td width="45%" style='white-space: nowrap;'>Book vs. Req</td>
				            <td width="50%">
					     	<span class="DDProgress">
					     	<GProgressX :height="12" :width='80' :percentage="100*dataInfo.info['Booked Amount']/dataInfo.info['Req. Amount']"/>
					     	</span>
					     	</td>
				            </tr>
				        	<tr v-for="(o,key,i) in filter(dataInfo.info)" :key="'inf_'+i">
					     	<td width="45%" style='white-space: nowrap;'>{{key}}</td>
					     	<td width="50%">
					     	<div v-if="key.startsWith('Avg')" :title='print(o, key, dataInfo)' style='font-weight: bold; width:calc(100) !important; white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis;'>
					     	{{print(o, key, dataInfo)}}
					     	</div>
					     	<div v-else :title='print(o, key, dataInfo)' style='width:calc(100) !important; white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis;'>
					     	{{print(o, key, dataInfo)}}
					     	</div>
					     	</td>
					     	</tr>
				        </table>
				     	
				     </div>
 				</div>
 				
		       </div>
		       
    	   </div> 
    	  
    	   
    	   
        </div>
        
        <div v-if='user && user.id' class='transitionNone' style='top: 0pt; width: 20%; height: calc(100vH - 156px); margin-top: 6pt; float: right;' >
        	<div v-if='showSettingsWidget' class='transitionNone'
	            :style="getAppStyle()+'float: top; border: 1pt solid #ccc; box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+';font-size: 10pt; float: top; width: 95%; padding-left: 5pt; margin-top: 10pt; margin-right: 10pt;'" 
	           >
		       <div  style=' width: 100%; float: left;'>
			       	<b>Settings</b>
			       	<div class='white iButton' :style="getAppStyle()+'float: right;'" @click='showSettingsWidget=false;'>
			       		<span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" :style="getAppStyle()" width="18" height="18" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
							  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
							  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
							</svg>
						  </span>
				   </div>
			       </div> 
			       
				<table width="85%" cellspacing=0 cellpadding00>
				<tr>
				<td width='68%'>
		       
		        </td>
		        <td rowspan=2>
		        <button :style="getAppStyle()" title='save all the changes done on this screen' class='buttonSmall' @click="userUpdated()">Save</button>
  					
  				</td>
  				<tr>
  				<td>
		  		
		  		</td>
		  		</tr>
		  		</table>
		        

				
		        <div style='width: 95%;padding-left: 5pt;'>
					<div style='padding-top: 2pt; padding-bottom: 5pt;'>
		        	Date-range (for Revenue charts and actions)
		        </div>
		       	<div style='display: block;  width: 100%;' >
		        <div style='display: inline-flex; width: 45%;'>
	    	    <InputDatePick :auto="false" 
                            v-model="fromDate"/>  
                            </div>
                            -
                <div style=' display: inline-flex; width: 45%;'>
                <InputDatePick :disabled="!true" :auto="false"
                            v-model="untilDate"/>     
                 </div>
                 
                  
                 <br/>
                 <div style='height: 5pt;'/>
                
                 
                 <div style='height: 5pt;'/>
                <div class='noLineBreak2'>
                 <div style='display: inline-flex; font-size: 10pt; width: 100pt;'>Show in </div>
                 <div style='display: inline-flex; font-size: 8pt; width: 60pt;'>chart</div>
                 <div style='display: inline-flex; font-size: 8pt; width: 60pt;'>compare</div>
                 </div>
                 <div v-for="(d,i,n) in seriesInfo.filter(p=>p.select)" :key="'s_'+n">
                   <div class='noLineBreak2'>
                   <div style='display: inline-flex; font-size: 8pt; width: 100pt;'>{{d.name}}</div>
                   <app-switch v-model="d.show" :checked="d.show"/>
                   <app-switch v-model="d.compare" :checked="d.compare"/>
                   </div>
                 </div>
                 
                 <div style='height: 5pt;'/>
                 
                 
                 Checks:<br/>
                 <div style='display: inline-flex; font-size: 9pt; width: 140pt;'>show all checks</div><app-switch v-model="showAllChecks" :checked="showAllChecks"/><br/>
                 <div style='height: 5pt;'/>
                 
                 <span class='dontWrap smallText bold'>Font-Size:</span>
		        <div style='width: 75%;padding-left: 5pt;'>
				<vue-slider @change='val => change("font")' :marks="false" min=10 max=16 v-model="fontSize"></vue-slider>	
				</div>
				<div style='height: 5pt;'/>
                 <div style='display: inline-flex; font-size: 9pt; width: 140pt;'>Make this the start screen</div><app-switch v-model="isStartScreen" :checked="isStartScreen"/><br/>
			    
			     <!--
                 <div style='display: inline-flex; font-size: 9pt; width: 140pt;'>Dark/Night Mode</div><app-switch v-model="darkMode" :checked="darkMode"/><br/>
				 -->
				 <br/>
				 
			     <span class='dontWrap smallText bold'>Shadow/Rounding:</span>
		        <div style='width: 75%;padding-left: 5pt;'>
				<vue-slider title='Shadow' @change="change('shadow1')" :marks="false" min=0 max=36 v-model="attr.shadow1"></vue-slider>	
				<vue-slider title='Shadow (blur-radius)' @change="change('shadow2')" :marks="false" min=0 max=36 v-model="attr.shadow2"></vue-slider>
				<vue-slider title='Rounding' @change="change('rounding')" :marks="false" min=0 max=36 v-model="attr.rounding"></vue-slider>
			    </div> 		
				</div></div>
				
				<br/>
					
	        </div>
	        <WIDGETS :class="showSettingsWidget?'invisibleItem':''"
	                 :widgets='widgets' :shadow1="shadow1" :shadow2="shadow2" :shadowColor="shadowColor"
	                 :widgetsSetup='widgetsSetup'
	                 :fontSize="Math.min(fontSize,11)" :user="user"
	                 @widgets="changeWidgets" 
	                 @widgetsSetup="changeWidgetsSetup" 
	                 @reload="getUser(true)"
	                 @save="userUpdated"
	                 @add="add" @open="open"
	                 @showSettings='showSettingsWidget=true'>
	        </WIDGETS>
        	
	        
        </div>
      </div>
  	</div>
  	
  	<GFWEOpenEditor ref='editor' @update='doRerunCheck()' ></GFWEOpenEditor>
  	
  	<ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">
      
        <ContextMenuItem v-if="contextData.check"  @clicked="$refs.menu.close(); $refs.editor.open( 'PlaylistCheck', contextData.check.id) ">
        	open Check '{{contextData.check.name}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.check"  @clicked="$refs.menu.close(); push( 'PlaylistCheck') ">
        	open module {{tr('PlaylistCheck')}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.check && showAllChecks"  @clicked="$refs.menu.close(); showAllChecks=false; ">
        	Show only checks with findings
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.check && !showAllChecks"  @clicked="$refs.menu.close(); showAllChecks=true; ">
        	Show all checks (including without findings)
        </ContextMenuItem>
        
        <ContextMenuSep v-if="contextData.check"/>
        
        <ContextMenuItem v-if="contextData.mediaId"  @clicked="$refs.menu.close(); displayEntity(contextData.mediaId, contextData.channel, 'Media')">
        	view channel '{{contextData.channel}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.mediaId"  @clicked="$refs.menu.close(); openChannelDashboard( contextData.mediaId)">
        	view channel dashboard '{{contextData.channel}}'
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.mediaId"/>
 
        <ContextMenuItem v-if="contextData.clientId"  @clicked="$refs.menu.close(); $refs.editor.open( 'Client', contextData.clientId) ">
        	open client '{{contextData.clientName}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.clientId"  @clicked="$refs.menu.close(); displayEntity(contextData.clientId, contextData.clientName, 'Client')">
        	view client '{{contextData.clientName}}'
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.clientId"/>
        
        <ContextMenuItem v-if="contextData.placementId"  @clicked="$refs.menu.close(); $refs.editor.open( 'Placement', contextData.placementId) ">
        	open {{tr('Placement')}} '{{contextData.placementName}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.placementId"  @clicked="$refs.menu.close(); displayEntity(contextData.placementId, contextData.placementName, 'Placement')">
        	view '{{contextData.placementName}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.placementId" @clicked="$refs.menu.close();  openBookManPlcm(contextData.placementId, contextData.from, contextData.to)">
         Open Traffic Manager for {{contextData.placementName}}
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.placementId"/>
        
        <ContextMenuItem v-if="contextData.copyId"  @clicked="$refs.menu.close(); $refs.editor.open( 'Copy', contextData.copyId) ">
        	open copy '{{contextData.copyName}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.copyId"  @clicked="$refs.menu.close(); displayEntity(contextData.copyId, contextData.copyName, 'Copy')">
        	view copy '{{contextData.copyName}}'
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.copyId"/>


       <ContextMenuItem @clicked="showColorPickerSHD($event);">
        	<div>set color for shadow</div>
        </ContextMenuItem>
       
         <ContextMenuItem v-if="colorTableSelect" @clicked="showColorPickerCTI($event);">
        	<div>set color for {{colorTableSelect}}</div>
        </ContextMenuItem>
         <ContextMenuItem v-if="colorTableSelectStart" @clicked="showColorPickerCTIStart($event);">
        	<div>set color for {{colorTableSelectStart}}</div>
        </ContextMenuItem>
       
        
        <ContextMenuSep />
        
         <ContextMenuItem  @clicked="getUser(true); $refs.menu.close();">
          Reload
        </ContextMenuItem>
        <ContextMenuItem  @clicked="userUpdated(); $refs.menu.close();">
          Save
        </ContextMenuItem>

       </template>
    </ContextMenu>
    <ProgressBar v-if="showProgressBar" :generalInfo=pbInfo :action=pbAction @action=action></ProgressBar>
    <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'set color for ' + selectedItem.title}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Set', btn1OnClick:closeColorPicker, btn2OnClick:closeColorPicker, disableBtn2:false}"
       name="modal-selectColumns"  @on-close="closeColorPicker">
		<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
        <color-panel v-model="selectedItem.color" @change='changes["color"]=1' ></color-panel>
		</div>
    </vue-modal-2>
   <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'set color for shadow'}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Set', btn1OnClick:closeColorPicker, btn2OnClick:closeColorPicker, disableBtn2:false}"
       name="modal-selectColumnsSHD"  @on-close="closeColorPicker">
		<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
        <color-panel v-model="attr.shadowColor" @change='changes["shadowColor"]=1' ></color-panel>
		</div>
    </vue-modal-2>
    
    <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'set color for '+colorTableSelect}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Set', btn1OnClick:closeColorPicker, btn2OnClick:closeColorPicker, disableBtn2:false}"
       name="modal-selectColumnsCTI"  @on-close="closeColorPicker">
		<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
        <color-panel v-model="colorTable[colorTableSelect]" ></color-panel>
		</div>
    </vue-modal-2>
    
    <vue-modal-2 :darkMode="true" 
       :headerOptions="{title: 'set color for '+colorTableSelectStart}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Set', btn1OnClick:closeColorPicker, btn2OnClick:closeColorPicker, disableBtn2:false}"
       name="modal-selectColumnsCTIStart"  @on-close="closeColorPicker">
		<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
        <color-panel v-model="colorTable[colorTableSelectStart]" ></color-panel>
		</div>
    </vue-modal-2>
   
  </div>   
</template>

<script>
import {HTTP, userAPI, searchAPI, reportAPI, fwAPI, opsAPI, bngAPI, dashboardInfoAPI, setReload, invoiceView, unregisterReload, formatNumber, setListener, unregisterListener, showError} from '@/variables.js';
import { initGoBack, setGoBack } from '@/breadCrumb.js';
import {store, restore} from '@/WorkbenchDataMiner.js';
import { defer, openView } from '@/utils.js';
import { getAppStyle, getAppStyleFG, setDarkMode, initAppMode, hexToRgb, isDark, getBG, getBGColor } from '@/AppStyle.js';
import GFWE from '@/views/GFWE';
import WIDGETS from '@/components/desktopWidgets/WorkbenchWidgets';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputText from '@/components/inputElements/InputText';
import InputSearch from '@/components/inputElements/InputSearch';
import GProgressX from '@/components/misc/GProgressOwn';
import GSelect from '@/components/misc/GSelect';

import GProgressRED from '@/components/misc/GProgressOwn';
import ProgressBar from '@/components/ProgressBar';
import ShowInfoDataDashboard from '@/components/misc/ShowInfoDataDashboard';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import BarChart from '@/components/misc/BarChartDataDashboard';
import vSelect from 'vue-select'
import Switch from '@/components/Switch';
import ApexCharts from 'apexcharts'
import { tr } from '@/translate.js';
var momentTZ = require('moment-timezone');
import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;
export default {
  name: "FWDataExplorer",
  components : {
   'app-switch': Switch, InputDatePick, ShowInfoDataDashboard, GProgressX, vSelect, ProgressBar,GProgressRED,
   GFWE, ContextMenu, ContextMenuItem, ContextMenuSep, InputSearch,
   WIDGETS, WindowCloseBN
  },
  data() {
      return {
         titles: ["Clients", "Agencies", "Product","Campaign","Placement","Copy"],
         buttons: ["Clients", "Agencies", "Holding", "ProductLabel", "Product","Families","Deal","Campaign","SalesRequest","Placement","Copy","Media","Grid"],
         entities: ["Client", "Client", "ClientGroup", "ProductLabel", "Product","ProductFamily","Deal","Campaign","SalesRequest","Placement","Copy", "Media", "GridDetail"],
         selectedTitle: 0,
         selectedModule: "Client",
         activeModule: "Client",
         view: "money",
         searchTerm: "",
         dataInfo: {},
         search: "",
         
         seriesInfo: [
        	 {id: 'revenueRatecardPaid', name: 'ratecard (paid)', show: false, compare: false, series: [], select: true },
	            {id: 'revenueTotal', name: 'total (nominal)', show: false, compare: false, series: [], select: true },
	            {id: 'revenuePaid', name: 'paid', show: false, series: [], compare: false, select: true },
	            {id: 'revenuePaidTarget', name: 'target', show: false, compare: false, series: [], select: false },
	            {id: 'revenueNet', name: 'net', show: false, series: [], compare: false, select: true },
	            {id: 'revenueTotal-revenuePaid', name: 'free/bonus', show: false, compare: false, series: [], select: true },
	            {id: 'revenueAired', name: 'aired', show: false, series: [], compare: false, select: true },
	            {id: 'revenueNotAired', name: 'not aired (missing)', show: false, compare: false, series: [], select: true },
	            {id: 'revenuePaid-revenueAired-revenueNotAired', name: 'not treated (not recon.)', show: false, compare: false, series: [], select: true },
	            {id: 'revenueInvoiced', name: 'invoiced', show: false, compare: false, series: [], select: true },
	            {id: 'items', name: 'spots', show: false, compare: false, series: [], noK: true, select: true },
	            {id: 'revenuePaid/seconds', name: 'avg. price/sec', show: false, compare: false, series: [], noK: true, select: true },
	            {id: 'revenuePaid/items', name: 'avg. price/spot', show: false, compare: false, series: [], noK: true, select: true }
             ],
        
         monthRevenue: [ { "name": "Spots", "data": [  ] } ],
         compareRevenue: null,
         compareRevenueTMP: null,
         labels: [],
         labelIDs: [],
         selectedData: {},
         compareWith: null,
         showGraph: false,
         searchword: "",
         entityList: [],
         reRunCheck: null,
         recentlyOriginal: [],
         lastRevenueData: {},
         colorTable: {},
         colorTableSelect: null,
         colorTableSelectStart: null,
         user: {},
         tasks: [],
         edit: "",
         cdata: {},
         data: {},
         highlightIssue: 99,
         availableChecks: null,
         checkResult: {},
         selectedCheck: {},
         selectedCheckResult: {},
         activatedCheck: {},
         activatedCheckResult: {},
         checkFilter: {},
         showAllChecks: true,
         runBackgroundCheck: {},
         initialCheckResults: {},
         checkChart: {},
         chart: {},
         
         chartType: "bar",
         chartTheme: "palette7",
         chartMarkers: "on",
         cumulative: 'individual',
         extraRequest: null,
         chartText: "",
         chartBase: 'amount',
         chartAllSeries: {},
         chartOptions: { chart: {type: 'bar'}},
         fromDate: null,
         untilDate: null,	
         isStartScreen: false,
         specialFn: null,
         widgetsSetup: {},
         widgets: ["task", "noty", "logs", "errorlogs", "search", "login"],
         multipleTables: true,
         autoAdd: false,
         darkMode: false,
         tr,
         getAppStyle,
        
         invoiceView,
         getBGColor,
         getBG,
         history: [],
         
         initSelected: ['node-1'],
         treeData1: [],
         attr: {},
         
         showProgressBar: false,
         pbAction: "",
         pbInfo: "",
         
         showSettingsWidget: false,
         showHeader: true,
         fontSize: 10,
		 changes: [],
		 shadow1: 0,
		 shadow2: 0,
		 rounding: 0,
		 
         shadowColor: '#000',
         shadowColorOver: [],
         selectedItem: {},
         itemNotify: [],
         itemWarn: [],
         initGoBack,
         
         houseCurrency: "",
         
         sampleData: null,
           
         treeConfig: { nodeWidth: 120, nodeHeight: 80, levelHeight: 200 }
      }
  },
  methods: {
	    removeModule( event, entity)
	  	{
	  		this.entityList = this.entityList.filter(p => p != entity);
	  	},
	    closeSettingsWidget()
	    {
	    	this.showSettingsWidget = false;
	    },
	    getColours4Issues( type)
	    {
	    	if (!this.colorTable[type])
	    	{
	    		this.colorTable[type] = "#fff";	
	    	}
	    	return "background-color: "+this.colorTable[type]+";color:"+(isDark(this.colorTable[type])?"#fff":"#000")+";";
				    
	    },
	    getTabW( id)
	    {
			return "40px"; //parseInt($('#'+id).css('width'), 10)-5;
			//return document.getElementById(id).getBoundingClientRect().width;
		},
	    tabChange(index)
        {
	    	this.view='money'
	    	this.data=[]; 
	    	this.activatedCheck = {};
	    	this.activatedCheckResult = {};
	    	this.checkFilter = {module: '', ids: []};
	    	this.searchTerm=''; 
	    	this.selectedTitle=index; 
	    	this.cdata=null, 
	    	this.showGraph=false; 
	    	this.selectedData={}; 
	    	this.selectedModule=this.entities[index]; 
	    	this.doSearch(true)
        },
       
        selectCheck( c)
        {
        	this.activatedCheck = c;
        	this.activatedCheckResult = {};
        },
        selectCheckResult( c)
        {
        	this.activatedCheckResult = c;
        	this.activatedCheck = {}
        },
        printColumn( val, column)
        {
        	if ( column.col === 'from' || column.col ==='to' )
        	{
        		return val.toLocaleDateString('en-CA');
        	}
        	if ( column.type==='date')
        	{
        		return new Date(val).toLocaleDateString('en-CA');
        	}
        	if (column.type==='money' ||column.type==='number' )
        	{
        		return formatNumber( val );
        	}
        	return val;
        },
        changeWidgets( newOnes )
        {
        	this.widgets = newOnes;
        },
        changeWidgetsSetup( newOnes )
        {
        	this.widgetsSetup = newOnes;
        },
        setCompare( data)
        {
        	if ( this.compareWith )
        	{
        		this.compareWith=null;
        		this.compareRevenue=null;
        	}
        	else
        	{
        		this.compareWith=data; 
        		this.compareRevenue=this.compareRevenueTMP;
        	}

        },
		getAvailableChecks()
		{
        	let that = this;
        	let loader = new Promise(function (resolve, reject) {
        		if (that.availableChecks)
            	{
        			resolve( that.availableChecks);
            	}
            	HTTP.post( dashboardInfoAPI+"/getChecks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, {})
    	            .then( response => 
    	            { 
    	            	that.availableChecks = response.data.filter(p=>p.dataMinerCheck);
    	            	//resolve (that.availableChecks);
    	            	resolve( that.availableChecks);
                }).catch(e => {
                	console.log(e)
                    showError(that.$toast, "get available checks", e);
                    reject ();
                });
        	});
        	return loader;
        	
		},
		openCheck() {
			this.view = 'checks';
			this.activatedCheck = {};
			this.activatedCheckResult = {};
			this.checkResult = null;
			this.distroyChart();
			this.reRunCheck = null;
			this.selectedCheckResult = {};
			let that = this;
			this.pbAction = "openCheckInternal";
	    	this.pbInfo = "performing checks";
	  	    this.showProgressBar = true;
		},
		openCheckInternal(myId, api) {
			let that = this;
			this.getAvailableChecks().then( checks => {
				//alert(checks.length)
				that.runCheck( myId, api, checks);
			});
		},
		action( what, myId, api)
	    {
	    	this[what](myId, api)
	    },
	    filterChecks( checks)
	    {
	    	if ( this.showAllChecks || this.highlightIssue === 0)
	    	{
	    		return checks;
	    	}
	    	return checks.filter(p=>(p.result.blocker+p.result.issues+p.result.warnings));
	    },
	    getCheckChart()
	    {
	    	let col = sessionStorage.fgColor;
	    	let chartOptions = {
					  chart: { 
					    type: 'pie',
					    	events: {
				    			  
							       dataPointSelection: (event,chartContext, config)=>{ this.checkChartClick(event,chartContext, config) }
						       },
	    				     animations: {easing: 'easeinout', speed: 400, animateGradually: { enabled: true }}
					  },
					  
					  series: [this.initialCheckResults.bookingsTotal-this.initialCheckResults.found,
						       this.initialCheckResults.warnings,
						       this.initialCheckResults.issues,
						       this.initialCheckResults.blocker
						       ],
						       colors: ['#5a5', '#ee0', '#ff9800', '#d66'],
					  markers: {
						  size: [4, 7]
						  
						},
						pie: {
					        customScale: 3.8
					      },
					      legend: {
					          
					          labels: {
					              colors: [col] 
					          }
					      },
					  stroke: {
						  width: 2
						},
						dataLabels: {
			  				  enabled: true,
			  				dropShadow: {
			  					enabled: false
			  				},
			  				    style: {
			  				      colors: ['#000']
			  				    }
			  				
			  				  },
						labels: ["No findings", "Warnings", "Issues", "Blocker"]
					}
	    	return chartOptions;
	    },
	    setRerunCheck( check)
	    {
	    	this.reRunCheck = check;
	    },
	    doRerunCheck()
	    {
	    	if ( this.reRunCheck)
	    	{
	    		this.rerunCheck( this.reRunCheck)	
	    	}
	    },
	    rerunCheck( check)
	    {
	    	let that = this;
	    	
	    	HTTP.post( dashboardInfoAPI+"/runChecks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0", [check])
            .then( response => 
            { 
            	that.checkFilter = {module: this.selectedTitle, ids: []};
            	let result = response.data.list[0];
            	
            	for (let xi in that.checkResult)
            	{
            		let elem = that.checkResult[xi];
            		
            		if ( elem.check.id === check.id )
            		{ 
            			that.checkResult[xi] = result;
            		}
            	}
				that.displayCheck(  result.check, result.result, result.actions, result.from, result.to, result.columns)
				that.$toast.success("Check "+check.name+" updated", 'Ok', { timeout: 1000, position: "topRight" });
            	//that.checkResult = that.checkResult.filter(p=>(p.result.blocker+p.result.issues+p.result.warnings));
            	//that.initialCheckResults = response.data.results;
            }).catch(e => {
	        	
	            showError(that.$toast, "loading data for check "+ check.name, e);
	            //reject ();
	        });
	    },
		runCheck( myId, api, check)
		{
			let that = this;
			this.selectedCheck = check;
			this.reRunCheck = null;
			//alert( myId)
			api.post( dashboardInfoAPI+"/runChecks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, check)
            .then( response => 
            { 
            	//alert( myId)
            	that.checkFilter = {module: this.selectedTitle, ids: []};
            	that.checkResult = response.data.list;
            	//that.checkResult = that.checkResult.filter(p=>(p.result.blocker+p.result.issues+p.result.warnings));
            	that.initialCheckResults = response.data.results;
            	//
            			
		            	
						that.highlightIssue = 99;
		            	that.distroyChart();
						that.checkChart = new ApexCharts(document.querySelector("#checkChart"), this.getCheckChart());
						that.checkChart.render();
            	
            	//
            	for ( let xi in that.checkResult )
            	{
            		let rec = that.checkResult[xi];
            		if ( rec.result.blocker )
            		{
            			rec.categ = 3;
            		}
            		else if ( rec.result.issues )
            		{
            			rec.categ = 2;
            		}
            		else if ( rec.result.warnings )
            		{
            			rec.categ = 1;
            		}
            		else
            		{
            			rec.categ = 0;
            		}
            	}
            	that.checkResult.sort((a, b) => {
				    let fa = a.result.blocker,
				        fb = b.result.blocker;
				
				    if (fa > fb) {
				        return -1;
				    }
				    if (fa < fb) {
				        return 1;
				    }
				    fa = a.result.issues,
			        fb = b.result.issues;
				    if (fa > fb) {
				        return -1;
				    }
				    if (fa < fb) {
				        return 1;
				    }
				    fa = a.result.warnings,
			        fb = b.result.warnings;
				    if (fa > fb) {
				        return -1;
				    }
				    if (fa < fb) {
				        return 1;
				    }
				    return 0;
				});
            	
            	this.showProgressBar = false;
				
            	return that.checkResult;
	        }).catch(e => {
	        	console.log(e)
	        	this.showProgressBar = false;
	            showError(that.$toast, "loading data for checks", e);
	            //reject ();
	        });
		},
		displayCheck(  check, result, actions, from, to, columnsTmp)
		{
			let that = this;
			this.selectedCheckResult = {};
			if ( actions && actions.length)
			{
				actions.sort((a,b)=>{
					if ( !a.date )
					{
						return a.text.localeCompare(b.text);
					}
					if ( !b.date)
					{
						return -a.text.localeCompare(b.text);
					}
					return new Date(a.date) - new Date(b.date)
				})
			}
			else
			{
				actions = [];
			}
		
			let columns = [...columnsTmp];
			this.selectedCheckResult.actions = actions;
			this.selectedCheckResult.from = from;
			this.selectedCheckResult.to = to;
			this.selectedCheckResult.columns = columns;
			
            	let tmp = result;
            	that.checkFilter = {module: this.selectedTitle, ids: []};
            	
            	for ( let x in tmp.issueSpots)
            	{
            		let spot = tmp.issueSpots[x];
	            	if ( this.buttons[this.selectedTitle]=='Clients')
	    	        {
	    	        	if ( !that.checkFilter.ids.includes( spot.clientId))
	    	        	{
	    	        		that.checkFilter.ids.push(  spot.clientId)
	    	        	}
	    	        	
	    	        }
	            	if ( this.buttons[this.selectedTitle]=='Product')
	    	        {
	    	        	if ( !that.checkFilter.ids.includes( spot.productId))
	    	        	{
	    	        		that.checkFilter.ids.push(  spot.productId)
	    	        	}
	    	        }
	            	if ( this.buttons[this.selectedTitle]=='Copy')
	    	        {
	    	        	if ( !that.checkFilter.ids.includes( spot.copyId))
	    	        	{
	    	        		that.checkFilter.ids.push(  spot.copyId)
	    	        	}
	    	        }
            	}
            	
            	that.selectedCheckResult.blocker = tmp.blocker;
            	that.selectedCheckResult.check = check;
            	that.selectedCheckResult.items = tmp.issueSpots.length;
            	that.selectedCheckResult.lines = {};
            	that.selectedCheckResult.channels = [];
            	for ( let xi in tmp.issueSpots)
            	{
            		let x = tmp.issueSpots[xi];
            		x.copyId = x.copyId?x.copyId:0;
            		let key = "";
            		for ( let xcol in columns)
            		{
            			let colDef = columns[xcol];
            			if ( !colDef.col.endsWith('total'))
            			{
            				key += x[colDef.col]+'.';
            			}
            		}
            		let d = new Date(x.date);
					
					
            		if ( that.selectedCheckResult.lines[key] && that.selectedCheckResult.lines[key].count)
            		{
            			that.selectedCheckResult.lines[key].count++;
            			
            			//console.log( JSON.stringify( that.selectedCheckResult.lines[key]))
            			that.selectedCheckResult.lines[key].amounttotal += x.gross5;
            			
            			that.selectedCheckResult.lines[key].invoicedtotal += x.invoiced;
            			that.selectedCheckResult.lines[key].nominaltotal += x.grossOrNominal;
            			//console.log(  key+"/"+that.selectedCheckResult.lines[key].nominaltotal)
            			that.selectedCheckResult.lines[key].invoicedtotal += x.grossHC;

            			if ( !columns.find(p=>p.col==='date'))
            			{
            				that.selectedCheckResult.lines[key].from = that.selectedCheckResult.lines[key].from < d ? that.selectedCheckResult.lines[key].from : d;
            				that.selectedCheckResult.lines[key].to = that.selectedCheckResult.lines[key].to > d ? that.selectedCheckResult.lines[key].to : d;
            			}
            		}
            		else
            		{
            			that.selectedCheckResult.lines[key] = { count: 1, from: d, to: d, nominaltotal: x.grossOrNominal}

        				that.selectedCheckResult.lines[key].amounttotal = x.gross5;
            			that.selectedCheckResult.lines[key].invoicedtotal = x.invoiced;
            			//that.selectedCheckResult.lines[key].nominaltotal = x.grossOrNominal;
            			//console.log( key+"/ I"+x.grossOrNominal+"+"+that.selectedCheckResult.lines[key].nominaltotal)
            			that.selectedCheckResult.lines[key].amounthctotal = x.grossHC;
            			for ( let xcol in columns)
                		{
                			let colDef = columns[xcol];
                			if ( !colDef.col.endsWith('total'))
                			{
	                			that.selectedCheckResult.lines[key][colDef.col] = x[colDef.col];
	                			if ( colDef.idCol )
	                			{
	                				that.selectedCheckResult.lines[key][colDef.idCol] = x[colDef.idCol];
	                			}
                			}
                			
                		}
            			
            			
            		}
            	}
            	columns.push({ header: 'Count', col: 'count'});
            	if ( !columns.find(p=>p.col==='date'))
    			{
            		columns.push({ header: 'From', col: 'from'});
            		columns.push({ header: 'To', col: 'to'});
    			}
            	this.selectedCheckResult.columns = columns;
            	that.checkFilter = {...that.checkFilter}
            	return that.selectedCheckResult;
	        
		},
		displayEntity(id, label, module)
		{
			let moduleIDX = this.entities.indexOf( module)
    		this.selectedTitle=moduleIDX; 
			this.selectedModule=this.entities[moduleIDX]; 
			this.doSearch(true)
            this.clickOn( {id: id, label: label}, null, module)
		},
		filterList( list, checkFilter)
        {
			//list = list.sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
			let tmp = list;
        	if ( checkFilter && checkFilter.ids && checkFilter.ids.length &&  checkFilter.module===this.selectedTitle)
        	{
	        	tmp = [];
	        	for ( let key in list)
	        	{
	        		let d = list[key];
	        		if ( checkFilter.ids.includes( d.id))
	        		{
	        			tmp.push(d);
	        		}
	        	
	        	}
        	}
        	let listStart=[];
        	let tmp2 = [];
        	for ( let d in tmp )
        	{
        		let startCh = tmp[d].label.substring(0,1);
        		if (!listStart.includes( startCh))
        		{
        			tmp2.push( {header: startCh});
        			listStart.push( startCh)
        		}
        		tmp2.push(tmp[d]);
        		
        	}
        	return tmp2;
        },
        checkChartClick(event,chartContext, config)
        {
        	if ( config.dataPointIndex >= 0 && config.seriesIndex >= 0)
        	{
        		if ( this.highlightIssue !== config.dataPointIndex )
        		{
        			this.highlightIssue = config.dataPointIndex;
        		}
        		else
        		{
        			this.highlightIssue = 99;
        		}
        	}
        },
        chartClick(event,chartContext, config, req, fieldIdentifier, module)
        {
        	
        	if ( config.dataPointIndex >= 0 && config.seriesIndex >= 0)
        	{
        		let filter = {};
        		if ( fieldIdentifier && this.labelIDs && this.labelIDs[config.dataPointIndex] >= 0)
        		{
        			filter[fieldIdentifier]=this.labelIDs[config.dataPointIndex];
        			
        		}
        		else
        		{
        			let d = new Date(req.fromDateISO);
        			let from = momentTZ(new Date(d.getFullYear(), config.dataPointIndex, 1)).utcOffset(0, true).format().split('T')[0]
            		let to = momentTZ(new Date(d.getFullYear(), config.dataPointIndex+1, 0)).utcOffset(0, true).format().split('T')[0]
            		req.fromDateISO = from;
            		req.untilDateISO = to;
        		}
        		//this.$refs.menu.open(event, {item: this.labels[config.dataPointIndex], refReq: req} );
        		
        		let copy = { ...req};
        		//alert(JSON.stringify( copy))
        		
        		let buttonArr = new Array();
        		let that = this;
        		let moduleIDX = this.entities.indexOf( module)
        		if (that.labelIDs[config.dataPointIndex])
        		{
	        		buttonArr.push( { text: "Monthly revenue view", onClick: function(){
	        			that.selectedTitle=moduleIDX; 
	        	    	that.selectedModule=that.entities[moduleIDX]; 
	        	    	that.doSearch(true)
			            that.clickOn( {id: that.labelIDs[config.dataPointIndex], label: that.labels[config.dataPointIndex] }, null, module)
			          }});
        		}
        		buttonArr.push( { text: "Traffic Manager screen", onClick: function(){
		            that.openBookManRR(config.dataPointIndex, req.fromDateISO, req.untilDateISO, copy, filter);
		          }});
        		
                buttonArr.push( { text: 'Cancel', onClick: function(){
        					            
        					          }});
            	this.$iosAlertView({
        	      title: 'Open '+this.labels[config.dataPointIndex],
        	      text: 'with',
        	      buttons: buttonArr
        	    });
        		
        	}        	
        },
        getTabPos()
        {
        	try
        	{
        		return $('#tabHead').position().top+$('#tabHead').height()+5;
        	} catch(e)
        	{
        		return 138;
        	}
        },
        filter( infos)
        {
        	let tmp = {};
        	for ( let key in infos)
        	{
        		let val = infos[key];
        		if ( key!=='Currency' || !Object.keys(infos).find(p=>p.includes('Amount')&&infos[p]))
        		{
        			tmp[key]=val;
        		}
        	
        	}
        	return tmp;
        },
        
        print( item, key, dataInfo)
        {
        
        	if ( isNaN( item) )
         	{
         		return item?item:"";
         	}
         	try
         	{
         		let toAdd = '';
         		if ( key.includes('/ratecard'))
         		{
         			item = parseFloat(item)*100;
         			toAdd = '%';
         		}
         		else if ( (key.includes('Value') || key.includes('Amount') || key.includes('/sec') || key.includes('price') || key.includes('Revenue')) && dataInfo.info.Currency)
         		{
         			toAdd = ' ' + dataInfo.info.Currency;
         		}
         		return item ? formatNumber( item ) +toAdd: item;
         	}
         	catch(e) {
         		//
         	}
        },
	    getLink( url, item)
	    {
        	let link = url + '?from='+ momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0]
	    	                 +'&to='+momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0];
        	
        	
        	if ( item.entity == 'SalesRequest' && url.includes('/reporter'))
        	{
        		link = link + '&reference='+item.label;
        		link = link + '&agencyId='+item.data.agencyId;
        		link = link + '&clientId='+item.data.advertiserId;
        		return link;
        	}
        	
	    	if ( this.buttons[ this.selectedTitle] == 'Agencies')
	    	{
	    		link = link + '&agencyId='+item.id;
	    	}
	    	else
	    	{
	    		link = link + '&'+this.selectedModule.toLowerCase()+'Id='+item.id;
	    	}
	    	return link;
	    },
	    select( module, item)
	    {
	    	let selectedTitle = 0;
	    	if ( module==+module)
	    	{
	    		selectedTitle = parseInt( module);
	    	}
	    	else 
	    	{ 
	    		selectedTitle = this.buttons.findIndex(p=>p==module);
	    		
	    		if (selectedTitle === null || selectedTitle<0 )
	    		{
	    			selectedTitle = this.entities.findIndex(p=>p==module);
	    		}
	    		
	    	}
	    	
	    	this.clickOn( item, selectedTitle, module);
	    },
	    goBackTo()
	    {
	    	if ( this.history.length > 1 )
	    	{
	    		return this.history[ this.history.length-2 ].item;
	    	}
	    	return null;
	    },
	    goBack()
	    {
	    	let req = this.history.pop();
	    	req = this.history.pop();
	    	if ( req )
	    	{
	    		this.clickOn( req.item, req.selectedTitle, req.module)
	    	}
	    },
	    clickOn( item, selectedTitle, module)
	    {
	    	this.view = 'money'
	    	//alert( JSON.stringify(item) + "/"+selectedTitle+"/"+this.selectedModule)
	    	this.history.push({item: item, selectedTitle: selectedTitle, module: module}); 
	    	if ( !selectedTitle || (selectedTitle < 100))
	    	{
	    		this.selectedData = item;
	    		this.getInfo( item, selectedTitle, module)
	    	}
	    	if ( this.view == 'money')
	    	{
	    		if ( item && item.id )
	    		{
	    			this.getRevenue( item, selectedTitle, module );
	    		}
	    	}
	    },
	    change(what) { this.$set( this.changes, what, true); },
	    push(module)
	    {
	  		if ( module.startsWith("/fw/"))
	  		{
	  			module = module.substring(4);
	  		}
	  		initGoBack( this.$router.currentRoute.path, "Data Miner for "+this.user.login);
	    	this.entityList.push(module);
	    	
	    },
	    openBookMan( item,from, to ) {
	        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	        //let channel = this.allAndFullStations.find(p=>p.id==billingRec.mediaId);
	        //let channelName = (channel) ? channel.shortname : null;
	        //alert(JSON.stringify( ))
	        let placementId = (item.data && item.data.placementId) ? item.data.placementId  : item.id;
	        //alert(placementId +"/"+ JSON.stringify(item ))
	        openView( this.$router, this.$toast, '/bookMan?toOpen=Placement&list=true&id='+ placementId+'&from='+ momentTZ(item.data.fromDate).utcOffset(0, true).format().split('T')[0]+'&to='+momentTZ(item.data.untilDate).utcOffset(0, true).format().split('T')[0]);
	    	//this.$router.replace('/bookMan?toOpen=Placement&list=true&id='+ placementId+'&from='+ momentTZ(item.data.fromDate).utcOffset(0, true).format().split('T')[0]+'&to='+momentTZ(item.data.untilDate).utcOffset(0, true).format().split('T')[0]);
	    },  
	    openBookManPlcm( item,from, to ) {
	    	
	        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	        openView( this.$router, this.$toast, '/bookMan?toOpen=Placement&list=true&id='+ item+'&from='+ from.toLocaleDateString('en-CA')+'&to='+to.toLocaleDateString('en-CA'));
	    },  
	    openBookManRR( item, from, to, reqRec, filter ) {
	        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	this.$router.replace({name:'Traffic Manager', params: {toOpen:'none',list:true, reqRec: JSON.stringify(reqRec), filter: JSON.stringify(filter), from: from, to: to}});
	    },  
	    openClientReport(id, from, to)
	    {
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	this.$router.replace({name:'Client Report', params: { from: from, to: to, clientId: id}});
	    },
	    openInvoicing(id, from, to)
	    {
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	
	    	let viewName = this.$router.matcher.getRoutes().find(p=>p.path===invoiceView).name;
	    	if ( !viewName )
	    	{
	    		showError( this.$toast, "cant detect invoicing screen name for "+invoiceView, 'Error', { position: "topRight" });
	    		return;
	    	}
	    	
	    	this.$router.replace({name: viewName, params: { from: from, to: to, clientId: id}});
	    },
	    copyColor( event, cell )
	    {
	        let col = this.recentlyOriginal[ cell.no-1 ];
	    	for ( let r in col )
	    	{
	    		col[r].color=cell.color;
	    		col[r].fcolor=cell.fcolor;
	    	}
	    	this.change('color')
	    },
	    
	    nodeClicked( event, node, v)
	    {
	    	
	    	this.$refs.editor.open(node.module, node.id)
	    },
	    
	   
	    
	  doSearch( force) {
	  	if ( force || (this.searchTerm))
		{
	    	this.search = this.searchTerm ? this.searchTerm: "*";
	    	this.getData();
		}
		
	  },
	  enter() {
	    	this.treeSearch();
	    },
	 treeSearch () {
	      this.$refs.tree1.searchNodes(this.searchword)
	    },
	  getData() {
	        let that = this;
	        that.info = "searching...";
	        let revReq = { fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
				       untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] };
	        let entity = that.entities[that.selectedTitle];
	        let title = that.buttons[that.selectedTitle];
	        
	    	let dataLoad = new Promise(function (resolve, reject) {
	            
	    		HTTP.post( searchAPI+"/qsearchEntity/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity+"/"+title+"/"+that.search, revReq)

	        		 .then( response => 
	                 {
	                    that.$set(that, "data", response.data);
	                    //console.log( JSON.stringify(response.data.request));
	                    that.data.meta = response.data.meta;//.filter(p=>p.column=='id');
	                    that.info = that.data.length +" module(s) found for '"+that.search+"'";
						that.$forceUpdate();
	                    resolve (that.data);                    
	                 }).catch(e => {
	                        showError( that.$toast, "loading data for search: " + e.response.data, 'Error', { position: "topRight" });
	                        //this.stopLoader();
	                        that.info = "";
	                        reject ();
	                    });
		      });
	    },
	    getInfo( item, menuIndex, module) {
	        let that = this;
	        
	        if ( that.dataInfo && that.dataInfo.moduleId == that.selectedTitle && that.selectedTitle.itemId == item.id )
	        {
	        	return that.selectedTitle;
	        }
	        that.dataInfo = {};
	        let revReq = { fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
				       untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] };
	        let menuIDX = (menuIndex===0 || menuIndex)?menuIndex:  that.selectedTitle;

	        that.info = "searching...";
	        
	        let entity = module ? module: that.entities[menuIDX];
	        let title = that.buttons[menuIDX]?that.buttons[menuIDX]: module;
	        this.activeModule = that.entities[menuIDX]?that.entities[menuIDX]:module;
	    	let dataLoad = new Promise(function (resolve, reject) {
	            //alert(dashboardInfoAPI+"/info/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity+"/"+title+"/"+item.id)
	       		HTTP.post( dashboardInfoAPI+"/info/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity+"/"+title+"/"+item.id, revReq)

	        		 .then( response => 
	                 {
	                	item.data = response.data.data;
	                	item.entity = entity;
	                	item.title = title;
	                    that.$set(that, "dataInfo", response.data);
	                    that.dataInfo.itemId = item.id;
	                    that.dataInfo.moduleId = that.selectedTitle;
						that.$forceUpdate();
	                    resolve (that.data);                    
	                 }).catch(e => {
	                        showError( that.$toast, "loading data for info: " + e.response.data, 'Error', { position: "topRight" });
	                        that.info = "";
	                        reject ();
	                    });
		      });
	    },
	   
	    getRevenue( item, menuIndex, module, cumulative, extra) 
	    {
	        let that = this;
	        
	        let revReq = { fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					       untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] };	
	        if ( extra )
	        {
	        	for ( let k in extra)
	        	{
	        		revReq[k] = extra[k];
	        		//alert(item+"/"+ menuIndex+"/"+ module +JSON.stringify( revReq));
	        	}
	        }
	        
	        let menuIDX = menuIndex;

	        if ( ! menuIDX && menuIDX !== 0)
	        {
	        	menuIDX = this.selectedTitle;
	        }
	        let prevItem = this.lastRevenueData.item;
	        this.lastRevenueData.item = {};
	        //alert( module +"/"+ menuIDX +"/"+ this.buttons[menuIDX])
	        if ( menuIDX == 100)
	        {
	        	if ( this.lastRevenueData && this.lastRevenueData.request )
	        	{
	        		this.lastRevenueData.item = prevItem;
	        		revReq = this.lastRevenueData.request;
	        		revReq.duration = item.id;
	        	}
	        }
	        else if ( menuIDX == 101)
	        {
	        	if ( this.lastRevenueData && this.lastRevenueData.request )
	        	{
	        		this.lastRevenueData.item = prevItem;
	        		revReq = this.lastRevenueData.request;
	        		revReq.businessType = item.id;
	        	}
	        }
	        else if ( this.buttons[menuIDX]=='Families')
	        {
	        	revReq.familyId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='Clients')
	        {
	        	revReq.clientId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='Agencies')
	        {
	        	revReq.agencyId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='Holding')
	        {
	        	revReq.holdingId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='Campaign')
	        {
	        	revReq.campaignId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='Placement')
	        {
	        	revReq.placementId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='SalesRequest')
	        {
	        	revReq.salesRequestId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='Media')
	        {
	        	revReq.channelId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='Grid')
	        {
	        	revReq.gridId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='Product')
	        {
	        	revReq.productId = item.id;
	        }
			else if ( this.buttons[menuIDX]=='ProductLabel')
	        {
	        	revReq.productLabelId = item.id;
	        }
	        else if ( this.buttons[menuIDX]=='Copy')
	        {
	        	revReq.copyId = item.id;
	        }
	        else if ( module=='Clashes')
	        {
	        	revReq.clashId = item.id;
	        }
	        else
	        {
	        	revReq.copyId = 0;
	        }
	        if ( menuIDX < 100)
	        {
		        this.lastRevenueData.request = revReq;
		        that.lastRevenueData.prevItem = that.lastRevenueData.item;
		        that.lastRevenueData.item = item;
		        
	        }
	        
	        HTTP.post( reportAPI+"/getRevenue/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, revReq)
		   		 .then( response => 
		            {
		            	let data = response.data;
		            	that.extraRequest = extra;
		            	that.labels = [];
		            	that.labelIDs = [];
		            	
		            	for (let i in this.seriesInfo)
		            	{
		            		this.seriesInfo[i].series = [];
		            	}
						// get and transform data
						// split data into array  [["fullpart", "id", "op"], ...]
						// min. 2 array elements, last one is empty
        	            const regexp = /([A-Za-z]*)([+-/]?)/g;
		            	for (let d in data)
		            	{
		            		that.labels.push( data[d].month);
		            		
		            		for (let i in this.seriesInfo)
			            	{
		            			let seriesElem = this.seriesInfo[i];

		            			let array = [...seriesElem.id.matchAll(regexp)]; // from object to array
		            			if ( !array[0][2].length ) // no operator
		            			{
		            				let divisor = seriesElem.noK ? 1: 1000;
		            				seriesElem.series.push( Math.round(data[d][array[0][1]]/divisor));
		            			}
		            			else
		            			{
		            				let firstOperator = array[0][2];
		            				let v1 = data[d][array[0][1]];
		            				let divisor = seriesElem.noK ? 1: 1000;
		            				if ( firstOperator === '-')
		            				{
		            					let v2 = data[d][array[1][1]];
		            					v1 = v1 - v2;
		            				}
		            				if ( firstOperator === '/')
		            				{
		            					let v2 = data[d][array[1][1]];
		            					if ( v2 && v1 )
		            					{
		            						v1 = v1 / v2;
		            					}
		            					else
		            					{
		            						v1 = 0;
		            					}
		            				}
		            				let secondOperator = array[1][2];
		            				if ( secondOperator === '-')
		            				{
		            					let v3 = data[d][array[2][1]];
		            					v1 = v1 - v3;
		            				}
		            				seriesElem.series.push( Math.round(v1/divisor));
		            			}
		            			
			            	}
		            		
		            	}
		                
		            	//let allSeries = [seriesPaid,seriesNet,seriesTarget,seriesTotal,seriesFree,seriesAired,seriesNotAired,seriesInvoiced,seriesUnknown,seriesSpots];
		            	cumulative = cumulative ? cumulative : (this.cumulative === 'cumulative');
		            	
		            	
		            	that.monthRevenue = [ ];
		            	that.compareRevenueTMP = [];
		            	if ( extra && extra.channelTarget)
		            	{
		            		
		            		that.monthRevenue.push({ "name": "Booked/Paid", "data": this.seriesInfo.find(p=>p.id==='revenuePaid').series });
		            		that.monthRevenue.push({ "name": "Target", "data": this.seriesInfo.find(p=>p.id==='revenuePaidTarget').series });
		            		that.chartText = (that.selectedData && that.selectedData.label)?(that.selectedData.label + ' (Booked Revenue vs. Target in T'+that.houseCurrency+')'):''
				            	
		            	}
		            	else
		            	{
		            		that.chartText = (that.selectedData && that.selectedData.label)?(that.selectedData.label + ' (Revenue in T'+that.houseCurrency+')'):''
		            		for (let i in this.seriesInfo)
				            {
		            			let data = this.seriesInfo[i];
		            			if ( data.show )
		            			{
		            				if ( !data.noK )
		            				{
		            					that.monthRevenue.push({ "name": data.name + ' in T'+that.houseCurrency, "data": data.series });
		            				}
		            				else
		            				{
		            					that.monthRevenue.push({ "name": data.name, "data": data.series });
		            				}
		            			}
		            			if ( data.compare )
		            			{
		            				if ( !data.noK )
		            				{
		            					that.compareRevenueTMP.push({ "name": data.name + ' in T'+that.houseCurrency+ " ("+that.selectedData.label+")", "data": data.series });
		            				}
		            				else
		            				{
		            					that.compareRevenueTMP.push({ "name": data.name+ " ("+that.selectedData.label+")", "data": data.series });
		            				}
		            			}
				            }
		            		
		            	}
		            	if ( that.compareWith && that.compareRevenue )
		            	{
		            		that.monthRevenue = [...that.compareRevenue];
		            		for ( let i in that.compareRevenueTMP)
		            		{
		            			let cWith = that.compareRevenueTMP[i];
		            			//alert( JSON.stringify(cWith))
		            			that.monthRevenue.push( { "name": cWith.name, "data": cWith.data })
		            		}
		            		that.chartText = that.compareWith.label+" vs. "+ that.selectedData.label;
		            	}
		            	if ( cumulative )
		            	{
		            		for ( let s in this.monthRevenue )
		            		{
		            			let serie = this.monthRevenue[s].data;
		            			//alert( JSON.stringify( serie))
			            		let budgetCum = 0;
			            		for ( let k in serie )
			            		{
			            			budgetCum += serie[k];
			            			serie[k] = budgetCum;
			            		}
		            		}
		            	}
		            	that.$set(that, "showGraph", true);
						if ( that.chartType === 'pie' || that.chartType === 'donut')
		            	{
		            		that.chartType = 'area';
		            	}
						let col = sessionStorage.fgColor;
						let colors = [];
						for (let ym in this.seriesInfo.find(p=>p.id==='revenueTotal').series)
						{
							colors.push( col);
						}
												
		            	that.chartOptions = {
						  chart: { 
						    type: that.chartType,
						    
						    events: {
						    			   xAxisLabelClick: (event,chartContext, config)=>{ this.chartClick(event,chartContext, config) },
						    			   legendClick: (event,chartContext, config)=>{ this.chartClick(event,chartContext, config) },
									       markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config})=>{ this.chartClick(event,chartContext, config, revReq, menuIDX)},
									       dataPointSelection: (event,chartContext, config)=>{ this.chartClick(event,chartContext, config, revReq, menuIDX) }
								}
						  },
						  stroke: {
							  show: true,
					    	  curve: 'smooth',
					    	  width: 2
					    	},
						  series: that.monthRevenue,
						  plotOptions: {
							  pie: { customScale: 0.5 }
						  },
						  dataLabels: {
							  enabled: this.chartMarkers==='on'
						  },
						  legend: {  labels: { colors: [col]}},
						  markers: {
							  size: [2],
						      colors: [col]
							},
						 
						  xaxis: {
						    categories: that.labels,
						    forceNiceScale: true,
						    labels: { style: {colors: colors}}
						  },
						  yaxis: {
			  					forceNiceScale: true,
			  			        decimalsInFloat: 2,
			  			        labels: { style: {colors: [col]}}
			  			  },
						  theme: {
							  palette: that.chartTheme // upto palette10
							}
						}
						
		            	that.distroyChart();
						that.chart = new ApexCharts(document.querySelector("#aChart"), that.chartOptions);
						
						that.chart.render();
					            	
					    that.$forceUpdate();
		                   
		            }).catch(e => {
		            	console.log(e.response)
		            	showError( that.$toast, "loading revenue data", e);
		          });
	    },
	    distroyChart()
	    {
	    	if ( this.chart)
			{
	    		try
	    		{
					this.chart.destroy();
					this.chart = null;
				}
	    		catch(e)
	    		{
	    		//
	    		}
			}
	    	if ( this.checkChart)
			{
	    		try
	    		{
					this.checkChart.destroy();
					this.checkChart = null;
				}
	    		catch(e)
	    		{
	    		//
	    		}
			}
	    },
	    setChartAttr()
	    {
	    	let that = this;
	    	try {
		    	//this.chart.updateOptions({chart: {type: this.chartType}, });
		    	
		    	that.chartOptions.chart.type = that.chartType;
		    	that.chartOptions.theme.palette = that.chartTheme;
		    	that.chartOptions.series = that.monthRevenue;
		    	that.chartOptions.dataLabels.enabled = that.chartMarkers==='on';
		    	
		    	that.distroyChart();
				that.chart = new ApexCharts(document.querySelector("#aChart"), that.chartOptions);
				
				that.chart.render();
			            	
			    that.$forceUpdate();
	    	}
		    catch (e) {
		    	//
		    }
		  
	    },
	    
	    changeChartBase( base)
	    {
	    	this.monthRevenue = this.chartAllSeries[base];
	    	this.setChartAttr();
	    },
	    
	    getEntitySplit( item, from, entity, spotIdField ) 
	    {
	        let that = this;
	       
	        let revReq = { fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					       untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] };	    
	        revReq[from] = item.id;
	        let displayName = tr(entity).toLowerCase();
	        HTTP.post( reportAPI+"/getEntitySplit/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity, revReq)
		   		 .then( response => 
		            {
		            	let data = response.data;
		            	that.labels = [];
		            	that.labelIDs = [];
		            	
		            	let seriesPaid = [];
		            	let seriesTotal = [];
		            	let seriesAired = [];
		            	let seriesInvoiced = [];
		            	let seriesSpots = [];
		            	
		            	for (let d in data)
		            	{
		            		that.labels.push( data[d].label?data[d].label:"no "+displayName);
		            		that.labelIDs.push( data[d].label?data[d].id:0);
		            		seriesPaid.push( Math.round(data[d].revenuePaid/1000));
		            		seriesTotal.push( Math.round(data[d].revenueTotal/1000));
		            		seriesAired.push( Math.round(data[d].revenueAired/1000));
		            		seriesInvoiced.push( Math.round(data[d].revenueInvoiced/1000));
		            		seriesSpots.push( data[d].items);
		            	}
		            	that.chartAllSeries = {"amount": seriesTotal, "spots": seriesSpots, "grp": seriesTotal};
		            	if ( that.chartType !== 'pie' && that.chartType !== 'donut')
		            	{
		            		that.chartType = "pie"; 
		            	}
		            	if ( !that.chartBase)
		            	{
		            		that.chartBase = 'amount'
		            	}
		            	that.monthRevenue = seriesTotal;
		            	that.chartText = (that.selectedData && that.selectedData.label)?(that.selectedData.label + ' ('+displayName+'-split)'):''
		            	 
						//alert( JSON.stringify(that.monthRevenue) )	
		            	//that.selectedData = item;
		            	that.$set(that, "showGraph", true);
		            	let col = sessionStorage.fgColor;
		            	that.chartOptions = {
								  chart: {
								    type: that.chartType,
								    animations: {easing: 'easeinout', speed: 800, animateGradually: { enabled: true }},
								    events: {
								    	dataPointSelection: (event,chartContext, config)=>{ this.chartClick(event,chartContext, config, revReq, spotIdField, entity) }
										}
								  },
								  series: that.monthRevenue,
								  labels: that.labels,
								  plotOptions: {
									  pie: { customScale: 0.7 }
								  },
								  legend: {  labels: { colors: [col]}},
								  theme: {
									  palette: that.chartTheme // upto palette10
									}
								}
						if ( that.chart)
						{
							that.chart.destroy();
						}
						that.chart = new ApexCharts(document.querySelector("#aChart"), that.chartOptions);
						
						that.chart.render();
		            	that.$forceUpdate();
		                   
		            }).catch(e => {
		            	showError( that.$toast, "loading revenue data", e);
		          });
	    },
	    
	    
	    open( menu)
	    {
	    	//alert( JSON.stringify( menu))
	    	this.push( menu.entity)
	    },
	    add( menuEntity, menuTitle)
	    {
	    	if ( ! this.recentlyOriginal || ! this.recentlyOriginal[0] )
    		{
    		    this.recentlyOriginal = [];
    			this.recentlyOriginal[0] = [];
    			this.titles = [];
    			this.titles.push(menuEntity);
    			this.change('moduleAdd')
    		}
    		this.recentlyOriginal[0].push({ no: 1, color: '#ccc', fcolor: '#000', title: menuTitle, module: menuEntity});
	    },
	    addModule(m, t) 
	    {
	    	//alert( m);
	    	if ( this.autoAdd )
	    	{
	    		this.add(m,t);
	    		return null;
	    	}
	    	return m;
	    },
	    showColorPicker( event, item)
	    {
	        this.selectedItem = item;
	        this.$refs.menu.close();
	    	this.$vm2.open('modal-selectColumns');
	    	
	    },
	    showColorPickerSHD( event)
	    {
	        this.$refs.menu.close();
	    	this.$vm2.open('modal-selectColumnsSHD');
	    	
	    },
	    setColorTableSelection(x, y) {
	    	this.colorTableSelect=x;
	    	
	    	this.colorTableSelectStart=y;
	    },
	    showColorPickerCTI( event)
	    {
	        this.$refs.menu.close();
	    	this.$vm2.open('modal-selectColumnsCTI');
	    	
	    },
	    showColorPickerCTIStart( event)
	    {
	        this.$refs.menu.close();
	    	this.$vm2.open('modal-selectColumnsCTIStart');
	    	
	    },
	    closeColorPicker()
	    {
	    	this.$vm2.close('modal-selectColumns');
	    	this.$vm2.close('modal-selectColumnsSHD');
	    	this.$vm2.close('modal-selectColumnsCTI');
	    	this.$vm2.close('modal-selectColumnsCTIStart');
	    },
	    switchColor( item)
	    {
	    	this.$set( item, 'fcolor', (item.fcolor === '#000') ? '#fff' : '#000');
	    	this.change('color')
	    	this.$forceUpdate();
	    },
	  	
	  	activeCells()
	  	{
	  		let x = [];
	  		for ( let i = 0; i <= 10; i++)
	  		{
	  			if ( this.isActive(i))
	  			{
	  				x.push(i);
	  			}
	  			
	  		}
	  		return x;
	  	},
	  	isActive( lineNo)
	  	{
	  		return this.recentlyOriginal[lineNo] && this.recentlyOriginal[lineNo].length
	  	},
	  	ctxMenu(event, item) 
        { 
	  	
	  	  event.preventDefault();
          
          if ( this.activatedCheck && this.activatedCheck.check && this.activatedCheck.check.id)
          {
        	  this.$refs.menu.open( event, this.activatedCheck);
          }
          else if ( this.activatedCheckResult && this.activatedCheckResult.clientId)
          {
        	  this.$refs.menu.open( event, this.activatedCheckResult);
          }
          else 
          {
        	  this.$refs.menu.open( event, {} );
          }
          
        },
        setModule(item) 
        { 
          this.activeItem = item;
        },

	    clearAll()
	    {
	    	this.entityList=[];
	    	setReload(null);
	    },
	    beautify( recentlyOriginal)
	    {
	    	let titleArr = [];
	    	let tmp = [];
	    	
    		for ( let l = 0; l <= 10; l++ )
    		{
    			let lineArr = recentlyOriginal[ l];
    			if ( lineArr && lineArr.length )
    			{
    				titleArr.push(  this.titles[tmp.length]);
    				for ( let r in lineArr )
    				{
    					let elem = lineArr[ r];
    					elem.no = tmp.length + 1;
    				}
    				tmp.push( lineArr);
    			}
    			
    		}
    		this.titles = titleArr;
    		return tmp;
	    },
	  	userUpdated() { 
	    		let that = this;
	    		if (!this.user)
	    		{
	    			this.getUser();
	    		}
	    		this.user.favorites.widgets = this.widgets;
	    		this.user.favorites.darkMode = this.darkMode;
	    		this.user.favorites.widgetsSetup = this.widgetsSetup;
				this.user.favorites.editorStyle=sessionStorage.editorStyle?sessionStorage.editorStyle:'classic';
				this.user.favorites.tabLayoutIDX=sessionStorage.tabLayoutIDX?sessionStorage.tabLayoutIDX:0;
	    		let seriesSetup = [];
	    		for ( let i in that.seriesInfo)
	    		{
	    			let data = that.seriesInfo[i];
	    			delete data.series;
	    			seriesSetup.push({ data})
	    		}
	    		
	    		this.user.dataMiner = { 
	    				 seriesSetup: seriesSetup,
	    				
	 					 chartType: that.chartType,
	 					 chartTheme: that.chartTheme,
	 					 chartBase: that.chartBase,
	 					 
	 					 fromDate: new Date(that.fromDate).toLocaleDateString('en-CA'),
	 					 untilDate: new Date(that.untilDate).toLocaleDateString('en-CA')
	    		};
	    		
	    		this.user.dataMiner.showAllChecks = that.showAllChecks;
	    		this.user.dataMiner.fontSize = this.fontSize;
	    
	    		this.user.dataMiner.shadow1 = this.attr.shadow1;
	    		this.user.dataMiner.shadow2 = this.attr.shadow2;
	    		this.user.dataMiner.colorTable = {};
	    		for ( let x in this.colorTable)
	    		{
	    			this.user.dataMiner.colorTable[x] = this.colorTable[x];
	    		}
	    		
	    		
	    		this.user.dataMiner.rounding = this.attr.rounding;
	    		this.user.dataMiner.shadowColor= this.attr.shadowColor;
	    		if ( that.isStartScreen ) 
	    		{
	    			that.user.firstScreen = 1;
	    		}
	    		else
	    		{
	    			that.user.firstScreen = 0;
	    		}
	    		//alert(that.user.firstScreen) 
				//alert(userAPI+"/setUserFavorites/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
	    		//alert(JSON.stringify(this.user.favorites))
				HTTP.put( userAPI+"/setUserDataMiner/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, that.user)
	            .then( response => 
	            { 
	 				that.user = response.data;
	 				//alert(that.user.firstScreen) 
	 				that.$toast.success("User "+that.user.login+" updated", 'Ok', { timeout: 1000, position: "topRight" });
	            }).catch(e => {
	            	showError( that.$toast, "update User", e)
	
	            });
	    },
	    transform()
	    {
	    	let that = this;
	    	let tmp = that.recentlyOriginal;
	    	let transposed = [];
	    	
			for ( let line in tmp )
			{
				let row = tmp[line];
				for ( let col in row )
				{
					let td = row[col];
					if ( ! transposed[col] )
					{
						transposed[col] = [];
					}
					transposed[col][line] = td;
				}
				//transposed[row.length][line] = transposed[row.length-1][line];
			}
			
			return transposed;
	    },
	    getUser(force) { 
	    	
	    	let promDefer = defer();
	    	if ( force || ! this.user || ! this.user.id)
	    	{
	    		
	    		let that = this;
	    		
	    		HTTP.post( userAPI+"/get4Dashboard/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId, this.entities)
	            .then( response => 
	            { 
	                that.user = response.data.user;
	                that.initialCheckResults = response.data.checkResult;
	                let allowed = response.data.allowed;
	                
	                if ( allowed )
	                {
	                	let buttonsTMP = [];
	                	let entitiesTMP = [];
	                	for ( let idx in that.entities)
	                	{
	                		let entity = that.entities[ idx];
	                		let tab = that.buttons[ idx];
	                		if ( allowed.includes( entity ))
	                		{
	                			buttonsTMP.push( tab);
	                			entitiesTMP.push( entity);
	                		}
	                	}
	                	that.buttons = buttonsTMP;
	                	that.entities = entitiesTMP;
	                }
	                
	                if ( !that.user.favorites )
	 				{
	 					that.recentlyOriginal = [];
	 				}
	 				else
	 				{
	 					if ( that.user.favorites.titles )
	 					{
	 						that.titles = that.user.favorites.titles;
	 					}
						sessionStorage.editorStyle = that.user.favorites.editorStyle?that.user.favorites.editorStyle:'classic';
						sessionStorage.tabLayoutIDX = that.user.favorites.tabLayoutIDX?that.user.favorites.tabLayoutIDX:0;
	 					that.multipleTables = that.user.favorites.multipleTables;
	 					that.darkMode = that.user.favorites.darkMode;
	 					that.widgets = that.user.favorites.widgets;
	 					that.widgetsSetup = that.user.favorites.widgetsSetup;
	 					if (!that.widgets )
	 					{
	 						that.widgets= [];
	 					}
	 					if (!that.widgetsSetup )
	 					{
	 						that.widgetsSetup= {};
	 					}
	 					that.showHeader = that.user.favorites.showHeader;
						
						that.$set( that, "shadowColor", that.user.favorites.shadowColor);
						that.$set( that, "shadow2", that.user.favorites.shadow2);
						that.$set( that, "shadow1", that.user.favorites.shadow1);
						that.$set( that, "rounding", that.user.favorites.rounding);
						
						let x = that.user.dataMiner;
						if ( !x || (!x.shadow1 && !x.fontSize))
						{
							x = that.user.favorites;
						}
						that.$set( that, "fontSize", x.fontSize);
						that.attr = {
								shadow1: x.shadow1,
								shadow2: x.shadow2,
								rounding: x.rounding,
								shadowColor: x.shadowColor
						}
						if ( ! that.fontSize )
						{
							that.fontSize = 9;
						}
		 				if ( that.user.dataMiner && that.user.dataMiner.chartTheme )
		 				{
		 					 let seriesSetup = that.user.dataMiner.seriesSetup;
		 					//lert(JSON.stringify(seriesSetup)) 
		 					 for ( let i in seriesSetup)
		 		    		 {
		 						let saved = seriesSetup[i].data;
		 		    			let data = that.seriesInfo.find( p=>p.id==saved.id);
		 		    			//alert( JSON.stringify(saved) +":"+ data.name + "/" + saved.id +"/"+ saved.show) 
		 		    			if ( data )
		 		    			{
		 		    				data.show = saved.show;
		 		    				data.compare = saved.compare;
		 		    			}
		 		    		 }
		 					 
		 					 that.chartType = that.user.dataMiner.chartType;
		 					 that.chartTheme = that.user.dataMiner.chartTheme;
		 					 that.chartBase = that.user.dataMiner.chartBase;
		 					 that.fromDate = new Date(that.user.dataMiner.fromDate);
		 					 that.showAllChecks = that.user.dataMiner.showAllChecks; 
		 					 that.untilDate = new Date(that.user.dataMiner.untilDate);
		 					 if ( that.user.dataMiner.colorTable)
		 					 {
		 						for ( let x in this.user.dataMiner.colorTable)
		 			    		{
		 							this.colorTable[x] = this.user.dataMiner.colorTable[x];
		 			    		}
		 					 }
		 					 that.isStartScreen = that.user.firstScreenValue === 'dataMiner';
		 				}
			            
		 			}
		 			
		 			if ( ! force )
		 			{
			 			if ( that.$route.params.module && that.$route.params.module !== 'myDashBoard' )
						{
						    if ( !that.entityList.includes( that.$route.params.module) )
						    {
						   	 	that.entityList.push( that.$route.params.module);
						   	}
						 }
				     }
		 			promDefer.resolve( that.user);
	            }).catch(e => {
	                   showError( that.$toast,  "loading user (workbench2)", e);
	                   promDefer.reject();
	            });
	       }
	       else
	       {
       			if ( this.$route.params.module && this.$route.params.module !== 'myDashBoard' )
				{
				    if ( !this.entityList.includes(this.$route.params.module) )
				    {
				   	 	this.entityList.push( this.$route.params.module);
				   	}
				 }
       			 promDefer.resolve( this.user);
	       }
	       return promDefer;	
	    },
	    openDetailPlan(stationId)
	    {
	    	let d = new Date();
	    	let isoDay = momentTZ( d).utcOffset(0, true).format().split('T')[0];
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	openView( this.$router, this.$toast, 'detailPlanning?stationId='+stationId+'&day='+ isoDay);
	    },
	    openDetailPlanWD(stationId, isoDay)
	    {
	    	//let d = new Date();
	    	//let isoDay = momentTZ( d).utcOffset(0, true).format().split('T')[0];
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	openView( this.$router, this.$toast, 'detailPlanning?stationId='+stationId+'&day='+ isoDay);
	    },
	    openAsrun(stationId)
	    {
	    	let d = new Date();
	    	let isoDay = momentTZ( d).utcOffset(0, true).format().split('T')[0];
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	openView( this.$router, this.$toast, 'AsRun?stationId='+stationId+'&day='+ isoDay);
	    },
	    openAvailsReport(stationId, date)
	    {
	    	let isoDay = date;
	    	if ( !date )
	    	{
	    		let d = new Date();
	    		isoDay = momentTZ( d).utcOffset(0, true).format().split('T')[0];
	    	}
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	openView( this.$router, this.$toast, 'availsReport?stationId='+stationId+'&day='+ isoDay);
	    },
	    openScheduleView(stationId)
	    {
	    	let d = new Date();
	    	let isoDay = momentTZ( d).utcOffset(0, true).format().split('T')[0];
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	openView( this.$router, this.$toast, 'scheduleView?stationId='+stationId+'&day='+ isoDay);
	    },
	    openChannelDashboard(stationId)
	    {
	    	let d = new Date();
	    	let isoDay = momentTZ( d).utcOffset(0, true).format().split('T')[0];
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	openView( this.$router, this.$toast, 'ChannelDashboard?stationId='+stationId+'&day='+ isoDay);
	    },
	    getCheckResults()
	    {
	    	let that = this;
	    	HTTP.post( dashboardInfoAPI+"/getCheckResults/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
            .then( response => 
            { 
                //that.initialCheckResults = response.data;
                that.$set(that, "initialCheckResults", response.data);
                that.$set(that.initialCheckResults, "blocker", response.data.blocker);
                that.$set(that.initialCheckResults, "found", response.data.found);
                that.$forceUpdate();
                //initialCheckResults
                //that.$toast.success("CheckResults updated", 'Ok', { timeout: 1000, position: "topRight" });
            }).catch(e => {
                showError( that.$toast,  "loading check results", e);

         });
	    },
	    storeSettings() 
		{
			try
			{
		    	let obj = this.$data;
		    	//this.userUpdated();
		    	store( obj);
			} catch(e) { showError( this.$toast, "storeSettings()", e);  }
		},
		reprintCheckChart()
		{
			let chartOptions = this.getCheckChart();
			this.highlightIssue = 99;
			this.checkChart = new ApexCharts(document.querySelector("#checkChart"), chartOptions);
	        this.checkChart.render(); 
	        if ( this.specialFn )
	        {
	        	clearInterval( this.specialFn );
	        }
	    	this.$forceUpdate();
		}
	  
  }, 
  beforeDestroy() {
	  this.storeSettings();
  },
  created() {
	initAppMode();  
	this.getUser().then( user => {
    if ( ! this.multipleTables )
	{
		this.entityList = [];
	}
    
	
	this.houseCurrency = sessionStorage.hCurrency;
	setListener( this.addModule );
	
	
	let userTMP = user;
	let initialCheckResultsTMP = this.initialCheckResults;
	let restoreView = restore();
	
	let d = new Date();
	this.fromDate = new Date(d.getFullYear(), 0, 1);
  	this.untilDate = new Date(d.getFullYear(), 11, 31);
  	
	if ( restoreView && restoreView.data && this.$route.params.open != 'checks')
	{
		this.$data = restoreView;
		this.selectedTitle = restoreView.selectedTitle;
		this.selectedModule = restoreView.selectedModule;
		this.fromDate = restoreView.fromDate;
		this.untilDate = restoreView.untilDate;
		this.dataInfo = restoreView.dataInfo;
		this.seriesInfo = restoreView.seriesInfo;
		
		if ( restoreView.colorTable)
		{
			this.colorTable = {}
			for ( let x in restoreView.colorTable)
 			{
				this.colorTable[x] = restoreView.colorTable[x];
 			}
		}
		this.view = restoreView.view;
		this.searchTerm = restoreView.searchTerm;
		this.search = restoreView.search;
		this.data = restoreView.data;
		this.cdata = restoreView.cdata;
		this.treeData1 = restoreView.treeData1;
		this.selectedData = restoreView.selectedData;
		this.monthRevenue = restoreView.monthRevenue;
		this.labels = restoreView.labels;
		this.labelIDs = restoreView.labelIDs;
		this.showGraph = restoreView.showGraph;
		this.selectedCheckResult = restoreView.selectedCheckResult;
		this.chartOptions = restoreView.chartOptions;
		this.checkResult = restoreView.checkResult;
		this.initialCheckResults = restoreView.initialCheckResults;
		
		if ( restoreView.reRunCheck )
		{
			this.rerunCheck( restoreView.reRunCheck );
		}
		
		if ( this.chartOptions) 
		{
			this.$nextTick(function () {
				this.distroyChart();
				this.chart = new ApexCharts(document.querySelector("#aChart"), this.chartOptions);
				
				this.chart.render();
			          	
				this.$forceUpdate();
			})
		}
		this.user = userTMP;
	  
	}
	else
	{
		this.selectedTitle=0;
		this.search="*";
		this.getData();
		if ( this.$route.params.open == 'checks' )
	 	{
	 		this.openCheck();
	 		return;
	 	}
	}
	this.runBackgroundCheck = setInterval( this.getCheckResults, 60000);
	let that = this;
	
	var runOnce = setInterval(
	    function () {
	    	that.buttons.push("x");
	    	that.$set( that, "buttons", that.buttons);
	    	that.buttons.pop();
	    	
	    	that.$forceUpdate();
	    	
		    clearInterval( runOnce);
	    },
	    4000);
	   setReload(this.getUser);
	  } )
	},
	mounted() {
		
		if (this.initialCheckResults && this.initialCheckResults.bookingsTotal && this.view==='checks')
		{
			
			let that = this;
			let chartOptions = that.getCheckChart();
			this.highlightIssue = 99;
			this.specialFn = setInterval( this.reprintCheckChart, 100);
				
		
		}
		
	
	
  },
  updated() {
	  initAppMode();
  },
  destroyed() {
  	unregisterReload( this.getUser );
  	unregisterListener( this.addModule );
  	if ( this.runBackgroundCheck)
  	{
  		clearInterval( this.runBackgroundCheck);
  	}
  },
  watch: {
	  entityList: function() { this.$nextTick(function () {this.$forceUpdate()}) },
	  cumulative: function() { this.getRevenue( this.selectedData, this.selectedTitle, this.selectedModule, null, this.extraRequest) },
	  darkMode: function(){ setDarkMode( this.darkMode); this.$forceUpdate();},
	  chartType: function() { this.setChartAttr()},
	  chartMarkers: function() { this.setChartAttr()},
	  chartTheme: function() { this.setChartAttr()},
        '$route' () {
            if ( ! this.multipleTables )
			{
				this.entityList = [];
			}
			
			this.getUser();
		 }
		 
    },
};
</script>
<style lang="scss" scoped>
th div { padding: 5pt; width: 100%; height: 100%; border-bottom: 2pt solid grey; }
th div:hover { 
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    //border: 1pt outset grey;
    cursor: pointer;
    background-color: #e8f0e8; }
th { padding: 0pt; }


tr[marked=true] { 
    //text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
font-weight: bold; }
.module { cursor: pointer; margin: 4pt; margin-bottom: 2pt; margin-right: 0pt; margin-left: 0pt; display: flex; background-color: #eef; 
          border: 1pt solid #ccc; width: 150px; height: 32pt; align-items: center; fy-content: center; 
           
          }


.task:hover { background-color: #888; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); font-weight: bold; }
//.module:hover { text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); font-weight: bold; }
.module:hover { background-color: #444 !important; color: #fff !important; 
   font-weight: bold; 
}
.withPaddingL { padding-left: 20pt;  padding-right: 5pt; }
.withPaddingR { padding-right: 20pt;  padding-left: 5pt; }
.reportItem {
	transition: all 2s;
   -webkit-transition: all 2s;
   font-size: 12pt;
   height: 12pt;
   cursor: pointer;
}
.tHeader {
	font-weight: normal;
	margin-bottom: -0pt !important;
	//display: inline-flex;
    justify-content: center;
    //border-radius: 6px;
    align-items: center;
    font-size: 10pt;
    //background-color: #f0714a;
    color: rgb(0, 0, 0);
    //box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.33);
}
.bigger {
	//background-color: #aaa;
	text-align: center;
	font-size: 9pt;
	font-weight: bold;
	padding-top: 3pt;
	height: 20pt;
}
.tLine {
	//font-weight: bold;
	margin-bottom: -0pt !important;
	cursor: pointer;
	//display: inline-flex;
    justify-content: center;
    //border-radius: 6px;
    align-items: center;
    font-size: 11pt;
    //background-color: #31d689;
    //color: rgb(0, 0, 0);
    //box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.33);
}
.small {
	font-size: 9pt;
}
.tiny {
	font-size: 8pt;
}
.smaller {
	font-size: 10pt;
}
.ok {
	color: #888;
	cursor: auto;
}
.noFindings 
{
}
.noFindings:after {
	content: "\2713";
    color: green;
}
.issue {
    cursor: pointer;
    font-size: 10pt;
}
.right {
	align-items: right;
	text-align: right;
}
.blocker {
	font-weight: bold;
	font-size: 10pt;
	color: #ff0000
}
.issueItem {
	font-weight: bold
}
.tLine:hover { background-color: #666 !important; color: #fff; text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7); }
.shadow {
	transition: all 2s;
   -webkit-transition: all 2s;
   box-shadow: 5px 5px 8px 0 #444;
}
.shadowLight {
	transition: all 2s;
   -webkit-transition: all 2s;
   box-shadow: 2px 2px 4px 0 #666;
}
.transitionNone {
 	transition: none !important;
   -webkit-transition: none !important;
 }
.reportItem:hover {
	background-color: #eef;
	
}
.invisibleItem { 
    visibility: hidden;
    opacity: 0;
    width: 0pt !important;
    height: 0pt !important;
    -webkit-transform: scale(0.0);
        -ms-transform: scale(0.0);
        transform: scale(0.0);
    transition: all 2s;
   -webkit-transition: all 2s;

}
.selected {
	//background-color: #d68931 !important;
	
	background-color: #31d689 !important;
    
    color: rgb(0, 0, 0) !important;
}
.selectedRed {
	//background-color: #d68931 !important;
	
	background-color: #bbb !important;
    
    color: rgb(0, 0, 0);
}
.buttonSmall{
	width: 100% !important;
	
    //padding: 6px 8px;
    outline: none;
    border-radius: 5px;
    height: 24pt;
    font-size: 8pt;
    //background-color: #31d689;
    background-color: #fff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-left: 4pt;
    margin-right: 4pt;
}

.buttonTab{
	width: 78.5pt !important;
	font-weight: bold;
	margin-bottom: -0pt !important;
	display: inline-flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    align-items: center;
    height: 34px;
    font-size: 8pt;
    //background-color: #31d689;
    //background-color: #fff;
    border-top: 1px solid #aaa;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
    //color: rgb(0, 0, 0);
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.33);
}
.buttonCheckTab{
	width: 68.5pt !important;
	font-weight: bold;
	margin-bottom: -0pt !important;
	display: inline-flex;
    justify-content: center;
    border-radius: 6px;
    align-items: center;
    height: 44px;
    font-size: 8pt;
    //background-color: #31d689;
    background-color: #fff;
    border-top: 1px solid #aaa;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
    color: rgb(0, 0, 0);
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.33);
}
.buttonTop{
	width: 10% !important;
	margin-top: 4px;
    //padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 30px;
    font-size: 8pt;
    //background-color: #31d689;
    background-color: #fff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    //margin-left: 4pt;
    //margin-right: 4pt;
}
.buttonAction{
    //display: block;
	width: 80% !important;
	margin-top: 2px;
    //padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22px;
    font-size: 11px;
    //background-color: #31d689;
    background-color: #fff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    //margin-left: 4pt;
    //margin-right: 4pt;
}
.buttonAction2{
    //display: block;
	width: 80% !important;
	margin-top: 2px;
    //padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 26px;
    font-size: 11px;
    //background-color: #31d689;
    background-color: #fff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    //margin-left: 4pt;
    //margin-right: 4pt;
}
.buttonActionSmall{
    //width: 40pt !important;
	
    //padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    font-size: 8pt;
    //background-color: #31d689;
    background-color: #fff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    //margin-left: 4pt;
    margin-right: 4pt;
}
.buttonActionSmall:hover{
   background-color: #31d689;
}
.buttonAction:disabled{
	background-color: #eee;
	color: rgb(8, 8, 8);
}
.buttonInside {
	width: 20% !important;
	
    //padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    //background-color: #31d689;
    background-color: #fff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    //margin-left: 4pt;
    margin-right: 4pt;
}

.BACK {
	background-color: #89d631;
}
.buttonTop:hover{
    background-color: #ccc;
}
.buttonTop:disabled {
    color: #aaa;
}
.buttonInside:hover{
	background-color: #ccc;
}
.buttonAction:hover{
	background-color: #ccc !important;
	color: #444 !important;
}
.buttonSmall:hover{
    background-color: #ccc !important;
	color: #444 !important;
}
.buttonTab:hover{
    background-color: #ccc !important;
	color: #444 !important;
}
.buttonCheckTab:hover{
   background-color: #ccc !important;
   color: #444 !important;
}
.mytable
{
    table-layout: fixed;
}
.ddItem { cursor: pointer; white-space: nowrap; font-size: 10pt; padding-left: 10pt; width: calc(100); overflow-x: hidden; white-space: nowrap; text-overflow: ellipsis; }
.ddItem:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
.ddItem:selected { background-color: #484 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.noBreak {
    max-width: 120px;
    white-space:nowrap;
    max-height: 100px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.noLineBreak {
    white-space:nowrap;
    max-height: 100px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.noLineBreak2 {
    white-space:nowrap;
    text-overflow: ellipsis;
}
.tree-node {
  display: block;
  width: 80px !important;
  height: 28px;
  border-radius: 50%;
  background-color: antiquewhite;
  text-align: center;
  line-height: 28px;
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
#aChart {
  max-width: 650px;
  margin: 35px auto;
}
.DDProgress {
	position: relative;
    display: inline-flex;
    //border: 1pt solid grey;
    align-items: center;
    width: 80pt !important;
    height: 18pt !important;
    justify-content: top;
    vertical-align: top !important;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-right: 0pt;
 	padding-bottom: 0pt;
	font-weight: normal;
	font-size: 8pt;
	//background-color: #fff;
}
.dt {
  display: block;
 
  color: #fff;
  font: bold 14px/18px Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 2px 0 0 12px;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
}
</style>